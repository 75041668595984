import {
  chainAssetsWithAddress,
  MarketMetadata as SDKMarketMetadata,
  MarketMetadataType as sdkMarketMetadataType,
  perennial,
  perennialSepolia,
  SupportedChainId,
  SupportedMarket,
} from '@perennial/sdk';
import { Address, getAddress, zeroHash } from 'viem'
import { arbitrum, arbitrumSepolia } from 'viem/chains';

import arbLogo from '../assets/icons/arb.png'
import bnbLogo from '../assets/icons/bnb.png'
import btcLogo from '../assets/icons/btc.png'
import celestiaLogo from '../assets/icons/celestia.png'
import dogeLogo from '../assets/icons/doge.png'
import ethLogo from '../assets/icons/eth.png'
import euroLogo from '../assets/icons/euro.png'
import gbpLogo from '../assets/icons/gbp.png'
import goldLogo from '../assets/icons/gold.png'
import jpyLogo from '../assets/icons/jpy.png'
import jupLogo from '../assets/icons/jup.png'
import linkLogo from '../assets/icons/chainlink.png'
import makerLogo from '../assets/icons/maker.png'
import meemLogo from '../assets/icons/meem.png'
import mogLogo from '../assets/icons/mog.png'
import msqBtcLogo from '../assets/icons/msqBtc.png'
import msqEthLogo from '../assets/icons/msqEth.png'
import polygonLogo from '../assets/icons/polygon.png'
import solanaLogo from '../assets/icons/solana.png'
import usdcLogo from '../assets/icons/usdc.png'
import xrpLogo from '../assets/icons/xrp.png'

export enum RewardAsset {
  usdc = 'usdc',
  arb = 'arb',
}

export const RewardAssetData: {
  [asset in RewardAsset]: { logo: string; name: string; symbol: string; pythFeedId: string }
} = {
  [RewardAsset.usdc]: {
    logo: usdcLogo,
    name: 'USDC',
    symbol: 'USDC',
    pythFeedId: '0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a',
  },
  [RewardAsset.arb]: {
    logo: arbLogo,
    name: 'Arbitrum',
    symbol: 'ARB',
    pythFeedId: '0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5',
  },
}

export enum MarketCategory {
  crypto = 'crypto',
  fx = 'fx',
  commodity = 'commodity',
  powerPerp = 'powerPerp',
  unknown = 'unknown',
}

export type MarketMetadataType = {
  [asset in SupportedMarket]: {
    icon: string;
    tvPythSymbol: string;
    tvTicker: string
    displayDecimals: number
    pythFeedId: string
    priceTooltip?: string
    isUnlisted?: boolean
    isInverted?: boolean
    symbolOverride?: string
    category?: MarketCategory
    isCryptexIndex?: boolean
  } & sdkMarketMetadataType[SupportedMarket];
};

export const MarketMetadata: MarketMetadataType = {
  [SupportedMarket.meem]: {
    icon: meemLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:MEEMUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.MEEM/USD',
    pythFeedId: SDKMarketMetadata.meem.providerId,
    isCryptexIndex: true,
    ...SDKMarketMetadata[SupportedMarket.meem],
  },
  [SupportedMarket.btc]: {
    icon: btcLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:BTCUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.BTC/USD',
    pythFeedId: '0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43',
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.btc]
  },
  [SupportedMarket.eth]: {
    icon: ethLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:ETHUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.ETH/USD',
    pythFeedId: '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.eth]
  },
  [SupportedMarket.arb]: {
    icon: arbLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:ARBUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.ARB/USD',
    pythFeedId: '0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5',
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.arb]
  },
  [SupportedMarket.link]: {
    icon: linkLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:LINKUSD',    
    displayDecimals: 6,
    tvTicker: 'Crypto.LINK/USD',
    pythFeedId: '0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221',
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.link]
  },
  [SupportedMarket.sol]: {
    icon: solanaLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:SOLUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.SOL/USD',
    pythFeedId: '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d',
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.sol]
  },
  [SupportedMarket.pol]: {
    icon: polygonLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:POLUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.POL/USD',
    pythFeedId: "0xffd11c5a1cfd42f80afb2df4d9f264c15f956d68153335374ec10722edd70472",
    isCryptexIndex: false,
    isUnlisted: true,
    ...SDKMarketMetadata[SupportedMarket.pol]
  },
  [SupportedMarket.tia]: {
    icon: celestiaLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:TIAUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.TIA/USD',
    pythFeedId: '0x09f7c1d7dfbb7df2b8fe3d3d87ee94a2259d212da4f30c1f0540d066dfa44723',
    isCryptexIndex: false,
    isUnlisted: true,
    ...SDKMarketMetadata[SupportedMarket.tia]
  },
  [SupportedMarket.bnb]: {
    icon: bnbLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:BNBUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.BNB/USD',
    pythFeedId: '0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f',
    isCryptexIndex: false,
    isUnlisted: true,
    ...SDKMarketMetadata[SupportedMarket.bnb]
  },
  [SupportedMarket.xrp]: {
    icon: xrpLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:XRPUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.XRP/USD',
    pythFeedId: '0xec5d399846a9209f3fe5881d70aae9268c94339ff9817e8d18ff19fa05eea1c8',
    isCryptexIndex: false,
    isUnlisted: true,
    ...SDKMarketMetadata[SupportedMarket.xrp]
  },
  [SupportedMarket.msqBTC]: {
    icon: msqBtcLogo,
    category: MarketCategory.powerPerp,
    tvPythSymbol: 'PYTH:BTCUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.BTC/USD',
    pythFeedId: '0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43',
    priceTooltip: 'BTC² / 1,000,000',
    symbolOverride: 'MICRO-BITCOIN² / US DOLLAR',
    isCryptexIndex: false,
    isUnlisted: true,
    ...SDKMarketMetadata[SupportedMarket.msqBTC]
  },
  [SupportedMarket.cmsqETH]: {
    icon: msqEthLogo,
    category: MarketCategory.powerPerp,
    tvPythSymbol: 'PYTH:ETHUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.ETH/USD',
    pythFeedId: '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
    priceTooltip: 'ETH² / 100,000',
    symbolOverride: 'CENTIMILLI-ETHEREUM² / US DOLLAR',
    isCryptexIndex: false,
    isUnlisted: true,
    ...SDKMarketMetadata[SupportedMarket.cmsqETH]
  },
  [SupportedMarket.jup]: {
    icon: jupLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:JUPUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.JUP/USD',
    pythFeedId: '0x0a0408d619e9380abad35060f9192039ed5042fa6f82301d0e48bb52be830996',
    isCryptexIndex: false,
    isUnlisted: true,
    ...SDKMarketMetadata[SupportedMarket.jup]
  },
  [SupportedMarket.rlb]: {
    icon: jupLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:JUPUSD',
    displayDecimals: 6,
    isUnlisted: true,
    tvTicker: 'Crypto.RLB/USD',
    pythFeedId: '0x2f2d17abbc1e781bd87b4a5d52c8b2856886f5c482fa3593cebf6795040ab0b6',
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.rlb]
  },
  [SupportedMarket.xau]: {
    icon: goldLogo,
    category: MarketCategory.commodity,
    tvPythSymbol: 'PYTH:XAUUSD',
    displayDecimals: 6,
    tvTicker: 'Metal.XAU/USD',
    isUnlisted: true,
    pythFeedId: '0x765d2ba906dbc32ca17cc11f5310a89e9ee1f6420508c63861f2f8ba4ee34bb2',
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.xau],
  },
  [SupportedMarket.mog]: {
    icon: mogLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:MOGUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.MOG/USD',
    pythFeedId: SDKMarketMetadata.mog.providerId,
    priceTooltip: 'MOG * 1,000,000',
    symbolOverride: '1,000,000 MOG / US DOLLAR',
    isUnlisted: true,
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.mog],
  },
  [SupportedMarket.jpy]: {
    icon: jpyLogo,
    category: MarketCategory.fx,
    tvPythSymbol: 'PYTH:JPYUSD',
    displayDecimals: 6,
    tvTicker: 'FX.USD/JPY',
    pythFeedId: SDKMarketMetadata.jpy.providerId,
    priceTooltip: '(1 / USDJPY) * 1,000',
    isInverted: true,
    isUnlisted: true,
    symbolOverride: '1,000 JAPANESE YEN / US DOLLAR',
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.jpy],
  },
  [SupportedMarket.mkr]: {
    icon: makerLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:MKRUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.MKR/USD',
    pythFeedId: SDKMarketMetadata.mkr.providerId,
    isUnlisted: true,
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.mkr],
  },
  [SupportedMarket.doge]: {
    icon: dogeLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:DOGEUSD',
    displayDecimals: 6,
    tvTicker: 'Crypto.DOGE/USD',
    pythFeedId: SDKMarketMetadata.doge.providerId,
    isUnlisted: true,
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.doge],
  },
  [SupportedMarket.eur]: {
    icon: euroLogo,
    category: MarketCategory.fx,
    tvPythSymbol: 'PYTH:EURUSD',
    displayDecimals: 6,
    tvTicker: 'FX.EUR/USD',
    pythFeedId: SDKMarketMetadata.eur.providerId,
    isUnlisted: true,
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.eur],
  },
  [SupportedMarket.gbp]: {
    icon: gbpLogo,
    category: MarketCategory.fx,
    tvPythSymbol: 'PYTH:GBPUSD',
    displayDecimals: 6,
    tvTicker: 'FX.GBP/USD',
    pythFeedId: SDKMarketMetadata.gbp.providerId,
    isUnlisted: true,
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.gbp],
  },
  [SupportedMarket.aero]: {
    icon: gbpLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:AEROUSD',
    displayDecimals: 6,
    tvTicker: 'FX.AERO/USD',
    pythFeedId: SDKMarketMetadata.aero.providerId,
    isUnlisted: true,
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.aero],
  },
  [SupportedMarket.popcat]: {
    icon: gbpLogo,
    category: MarketCategory.crypto,
    tvPythSymbol: 'PYTH:POPCATUSD',
    displayDecimals: 6,
    tvTicker: 'FX.POPCAT/USD',
    pythFeedId: SDKMarketMetadata.popcat.providerId,
    isUnlisted: true,
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.popcat],
  },
  [SupportedMarket.unknown]: {
    icon: solanaLogo,
    tvPythSymbol: 'PYTH:JUPUSD',
    displayDecimals: 0,
    tvTicker: 'Unknown',
    pythFeedId: zeroHash,
    isCryptexIndex: false,
    ...SDKMarketMetadata[SupportedMarket.unknown],
  },
}

export const SupportedMarkets = Object.keys(MarketMetadata).filter(
  (market) => !MarketMetadata[market as SupportedMarket].isUnlisted,
) as SupportedMarket[]

export const SupportedMarketsForChain = (chainId: SupportedChainId) =>
  chainAssetsWithAddress(chainId, SupportedMarkets).map(({ market }) => market)

export const SolverAddresses: {
  [chainId in SupportedChainId]: Address
} = {
  [arbitrum.id]: getAddress('0xeBc5BC1d2c12030E590EF5240c21f0F2b82ee37c'),
  [arbitrumSepolia.id]: getAddress('0xeBc5BC1d2c12030E590EF5240c21f0F2b82ee37c'),
  [perennial.id]: getAddress('0x55f566421F44f05C5623415EF150f86975d9CaDb'),
  [perennialSepolia.id]: getAddress('0x55f566421F44f05C5623415EF150f86975d9CaDb'),
}

export type MarketHours = {
  isOpen: boolean
  nextOpen: number | null
  nextClose: number | null
}