import axios from "axios";
import { useQuery } from '@tanstack/react-query'
import ProxyCheck from "proxycheck-ts"


export const useClientIp = () => { 
  return useQuery({
    queryKey: ['locationData'],
    enabled: true,
    refetchInterval: 300000,
    queryFn: async () => {
      const headers = { 'Authorization': process.env.REACT_APP_IPINFO_TOKEN };
      const ipInfo = await axios.get("https://ipinfo.io/", { headers });
      // console.log("ipInfo ", ipInfo)
      if (ipInfo.status === 200) {  
        return ipInfo.data.ip;
      } else {
        const ipApi = await axios.get("https://ipapi.co/json/");
        if (ipApi.status === 200) { 
          return ipApi.data.ip;
        } 
      }
      return "";
    }
  })
}

export const useProxyCheck = (currentIp: string) => {
  const proxyCheck = new ProxyCheck({ api_key: process.env.REACT_APP_PROXY_CHECK_API_KEY || "" });

  return useQuery({
    queryKey: ['proxyCheck', currentIp],
    enabled: !!currentIp && currentIp !== "",
    queryFn: async () => {
      try {
        const singleIPResult = await proxyCheck.checkIP(currentIp, {
          asn: 1,
          vpn: 3
        });
        // console.log("singleIPResult", singleIPResult)
        return singleIPResult[singleIPResult.ip.toString()];
      } catch (error) {
        console.log("error", error)
        return null;
      }
    }
  })
}
