import React from "react";
import { Button } from "react-bootstrap/esm";
import { useTranslation } from "react-i18next";
import { useGeofenceContext } from "../../contexts";


export const GeoblockMessage = () => {
  const { t } = useTranslation()
  const geofence = useGeofenceContext()

  return (
    <div className="geoblock-container">
      <Button className="btn btn-primary btn-lg btn-block " disabled={true}>{t("app-not-available")}</Button>
      <div className="p-2">
        <small className="text-muted">
          {geofence.isUsingProxy() || geofence.isUsingVpn()
            ? "You are using a VPN or Proxy. Please disable it to access the app."
            : t("info-msg.geoblock")}
          <a href="/terms-of-service" target="_blank">{t("terms-and-conditions")}.</a>
        </small>
      </div>
    </div>
  )
}
