import React from 'react'
import PerennialSdk from '@perennial/sdk'
import { createContext, useContext, useMemo } from 'react'
import { useWalletClient } from 'wagmi'

import { CryptexPricesUrl, PerpetualsGraphUrls, PythMainnetUrl, rpcUrls } from '../constants/network'
import { useAddress, usePerpetualsChainId } from '../hooks/network'
import { SupportedMarketsForChain } from '../constants/markets'

const SDKContext = createContext<PerennialSdk | null>(null)

export const PerennialSDKProvider = ({ children }: { children: React.ReactNode }) => {
  const { address } = useAddress()
  const chainId = usePerpetualsChainId()
  const walletClient = useWalletClient({ chainId })

  const sdk = useMemo(() => {
    if (!PythMainnetUrl) throw new Error('Missing PythMainnetUrl')

    return new PerennialSdk({
      chainId,
      rpcUrl:  rpcUrls[chainId] ?? "",
      walletClient: walletClient.data ?? undefined,
      graphUrl: PerpetualsGraphUrls[chainId],
      pythUrl: PythMainnetUrl,
      cryptexUrl: CryptexPricesUrl,
      storkConfig: {
        url: PythMainnetUrl,
      },  
      supportedMarkets: SupportedMarketsForChain(chainId),
      operatingFor: address,
    })
  }, [address, walletClient, chainId])

  return <SDKContext.Provider value={sdk}>{children}</SDKContext.Provider>
}

export const usePerennialSDKContext = () => {
  const context = useContext(SDKContext)
  if (!context) {
    throw new Error('useSDKContext must be used within a SDKContext provider')
  }
  return context
}