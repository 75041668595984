import React from "react";
import { Stack } from "react-bootstrap";

import { CtxIncentive } from "./components/CtxIncentive";

const Incentives = () => {

  return (
    <Stack direction="vertical" className="incentives p-5" gap={4}>
      <CtxIncentive />
    </Stack>
  );
}

export default Incentives;
