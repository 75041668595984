import React, { createContext, useContext, useMemo } from 'react'
import { notAllowedCountries } from '../constants/network';
import { useClientIp, useProxyCheck } from '../hooks/geolocation';
import { useLocation } from 'react-router-dom';


type geolocationDataType = {
  countryCode: string;
  isUsingVpn: boolean;
  isUsingProxy: boolean;
}

type GeofenceContextType = {
  geoLocationData: geolocationDataType | undefined,
  isRestrictedCountry: () => boolean,
  isUsingProxy: () => boolean,
  isUsingVpn: () => boolean,
  appDisabled: boolean,
}

const GeofenceContext = createContext<GeofenceContextType>({
  geoLocationData: undefined,
  isRestrictedCountry: () => true,
  isUsingProxy: () => true,
  isUsingVpn: () => true,
  appDisabled: true,
});

export const GeofenceProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { data } = useClientIp();
  const { data: proxyData, isLoading } = useProxyCheck(data);

  const geoLocationData = useMemo(() => { 
    if (!isLoading && proxyData !== undefined && proxyData !== null) {
      return {
        countryCode: proxyData.isocode,
        isUsingVpn: proxyData.vpn === "yes",
        isUsingProxy: proxyData.proxy === "yes",
      };
    }
  }, [proxyData, isLoading]);

  const isRestrictedCountry = (): boolean => {
    if (geoLocationData !== undefined) {
      if (location.pathname.includes("perpetuals")) {
        return geoLocationData.countryCode === "US" || notAllowedCountries.includes(geoLocationData.countryCode.toUpperCase());
      }
      return notAllowedCountries.includes(geoLocationData.countryCode.toUpperCase());
    }
    return true
  }

  const isUsingProxy = (): boolean => { 
    return false;
  }

  const isUsingVpn = (): boolean => { 
    return geoLocationData?.isUsingVpn || false;
  }

  const appDisabled = isRestrictedCountry() || isUsingProxy() || isUsingVpn();

  return (
    <GeofenceContext.Provider
      value={{
        geoLocationData,
        isRestrictedCountry,
        isUsingProxy,
        isUsingVpn,
        appDisabled,
      }}
    >
      {children}
    </GeofenceContext.Provider>
  );
}

export const useGeofenceContext = () => {
  const context = useContext(GeofenceContext)
  if (context === undefined) {
    throw new Error('useGeofenceProvider must be used within a useGeofenceProvider')
  }
  return context
}
