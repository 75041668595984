import React, { useMemo, useState } from "react";
import { Alert, Button, Image, Stack } from "react-bootstrap";
// import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { formatBig18 } from "@perennial/sdk";

import ctxIcon from "../../../assets/ctx-icon.png";
import { useUserRewards } from "../../../hooks/incentives";
import { useIncentivesTransactions } from "../../../hooks/vaults/incentives";


export const IncentivesBanner = () => { 
  const location = useLocation();
  const [show, setShow] = useState(true);
  const { data } = useUserRewards();
  const { onClaimCtx } = useIncentivesTransactions();
  
  const { userRewards } = useMemo(() => { 
    if (!data || data.userRewards.length === 0) return { userRewards: { claimed: 0n, availableToClaim: 0n } };

    return {
      userRewards: { claimed: BigInt(data.userRewards[0].claimed), availableToClaim: BigInt(data.userRewards[0].availableToClaim) },  
    };
  }, [data]);

  if (!show || location.pathname.includes("emissions") || userRewards.availableToClaim <= 1000n) return <></>;

  const onClaim = async () => { 
    await onClaimCtx(userRewards.availableToClaim);
    setShow(false);
  }

  return (
    <Alert onClose={() => setShow(false)} className="notification-box">
      <Stack direction="horizontal">
        <Stack direction="horizontal" className="w-90" gap={2}>
          <Image className="token-icon" src={ctxIcon} width={26} height={26} />
          <p>
            You have {formatBig18(userRewards.availableToClaim)} CTX available to claim.
            
          </p>
        </Stack>
        <Stack direction="horizontal" className="w-10 justify-content-end">
          <Button className="me-2 px-5 py-1" onClick={() => onClaim()}>
            Claim
          </Button>
        </Stack>
      </Stack>  
    </Alert>  
  )
}
