
import React from "react"
import ReactGA from "react-ga4";
import { CrypdexProvider } from "../../contexts/crypdexContext";
import Crypdex from "../../components/Crypdex";


const CrypdexPage = () => {
  ReactGA.send({ hitType: "pageview", page: "/indexes", title: "Indexes" });

  return (
    <CrypdexProvider>
      <Crypdex />
    </CrypdexProvider>
  )
};

export default CrypdexPage;
