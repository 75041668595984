import React, { useEffect, useMemo, useState } from "react"
import { Button, Image, Stack, Spinner } from "react-bootstrap";
import { Big18Math } from "@perennial/sdk";
import { useMediaQuery } from "react-responsive";

import { IndexInput } from "./IndexInput";
import { SetTokensList } from "./SetTokensList";
import { capitalize, isNumbersOnly, isPositiveNumber } from "../../../utils/utils";
import { useCrypdexTransactions } from "../../../hooks/crypdex/tx";
import { useCrypdexContext } from "../../../contexts/crypdexContext";
import { fetchAmountToIssue, useSetTokenPrice, useTokensBalances } from "../../../hooks/crypdex";
import { useChainId } from "../../../hooks/network";
import { ChainTokens, TokenMetadata, SupportedTokens } from "../../../constants/tokens";
import {
  ChainSetTokens,
  FlokiUniswapV2BuyTokensAddresses,
  SupportedComponents,
  SupportedSetTokens,
  SetTokenMetadata,
  ComponentMetadata,
} from "../../../constants/crypdex";
import { SetTokenInfo } from "./SetTokenInfo";
import {
  BuyComponentsDataType,
  SellComponentsDataType,
  fetchBuyComponentsData,
  fetchSellComponentData,
} from "../../../hooks/crypdex/paraswap";
import { AugustusSwapperAddress, DummyAddress, ParaswapErrorMessages } from "../../../constants/paraswap";
import { TokenInput } from "./TokenInput";
import { useAccount } from "wagmi";
import { formatUnits, parseUnits } from "viem";
import { UniswapV2RouterAddresses } from "../../../constants/contracts";
import { SetTokensComponents } from "./SetTokenComponents";
import { useGeofenceContext } from "../../../contexts";


enum TradeActions {
  issue = "issue",
  redeem = "redeem",
  info = "info",
  config = "config",
}

enum InputTypes {
  index = "index",
  other = "other",
}

export const IssueForm = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const chainId = useChainId();
  const { address: userAddress } = useAccount()
  const [inputValue, setInputValue] = useState("");
  const [indexAmountBI, setIndexAmountBI] = useState(0n);
  const { data: tokensBalances } = useTokensBalances();
  const { selectedSetToken, snapshots, userCurrentSetToken } = useCrypdexContext();
  const { data: setTokenPriceData } = useSetTokenPrice(selectedSetToken);
  const [fetchingTransferData, setFetchingTransferData] = useState(false);
  const [currentAction, setCurrentAction] = useState(TradeActions.issue);
  const [currentInputType, setCurrentInputType] = useState(InputTypes.index);
  const [indexToken, setIndexToken] = useState<SupportedSetTokens>(selectedSetToken);
  const [quotedToken, setQuotedToken] = useState<SupportedTokens>(SupportedTokens.usdc);
  const [quotedTokenAmount, setQuotedTokenAmount] = useState("");
  const [quotedTokenAmountBI, setQuotedTokenAmountBI] = useState(0n);
  const [buyComponentsData, setBuyComponentsData] = useState<BuyComponentsDataType>();
  const [sellComponentsData, setSellComponentsData] = useState<SellComponentsDataType>();

  const fetchTransferData = async () => { 
    try {
      if (indexAmountBI > 0) {
        setFetchingTransferData(true);
        const metadata = TokenMetadata[quotedToken];
        if (currentAction === TradeActions.issue) {
          setSellComponentsData(undefined);
          const buyData = await fetchBuyComponentsData(chainId, selectedSetToken, indexAmountBI, quotedToken, userAddress || DummyAddress);
          setBuyComponentsData(buyData);

          if (currentInputType === InputTypes.index) {
            const totalAmount = Object.keys(buyData).reduce((acc, key) => {
              return acc + buyData[key as SupportedComponents].srcAmountBI;
            }, 0n)

            setQuotedTokenAmountBI(totalAmount);
            setQuotedTokenAmount(formatUnits(totalAmount, metadata.decimals));
          }
        } else {
          setBuyComponentsData(undefined);
          const sellData = await fetchSellComponentData(chainId, selectedSetToken, indexAmountBI, quotedToken, userAddress || DummyAddress);
          setSellComponentsData(sellData);

          if (currentInputType === InputTypes.index) { 
            const totalAmount = Object.keys(sellData).reduce((acc, key) => {
              return acc + sellData[key as SupportedComponents].destAmountBI;
            }, 0n)

            setQuotedTokenAmountBI(totalAmount);
            setQuotedTokenAmount(formatUnits(totalAmount, metadata.decimals));
          }
        }
        setFetchingTransferData(false);
      } else if (indexAmountBI === 0n) {
        setQuotedTokenAmount("");
        setQuotedTokenAmountBI(0n);
        setBuyComponentsData(undefined);
        setSellComponentsData(undefined);
      }
    } catch (error) {
      setFetchingTransferData(false);
      console.error(error);
    }
  }

  const fetchIndexAmount = async () => { 
    if (currentAction === TradeActions.issue && currentInputType === InputTypes.other) {
      setFetchingTransferData(true);
      const quotedAmount = parseFloat(formatUnits(quotedTokenAmountBI, TokenMetadata[quotedToken].decimals));
      const indexValue = await fetchAmountToIssue(chainId, selectedSetToken, quotedToken, quotedAmount, userAddress || DummyAddress);
      setIndexAmountBI(indexValue.setTokenAmountBI);
      setInputValue(indexValue.setTokenAmount.toString());
      setFetchingTransferData(false);
    }
  }

  const calculateAmount = async () => { 
    if (currentInputType === InputTypes.index) { 
      fetchTransferData();
    } else {
      fetchIndexAmount();
    }
  }

  useEffect(() => {
    const intervalId = setInterval(calculateAmount, 70000);
    // Clear the interval on unmount
    return () => clearInterval(intervalId);
  },
    // eslint-disable-next-line
    [indexAmountBI]
  )

  useEffect(() => { 
    fetchTransferData();
  },
    // eslint-disable-next-line
    [chainId, selectedSetToken, currentAction, userAddress]
  )

  useEffect(() => { 
    const timer = setTimeout(() => { 
      fetchTransferData();
    }, 600);
    return () => clearTimeout(timer);  
  },
    // eslint-disable-next-line
    [indexAmountBI, quotedToken]
  )

  useEffect(() => {
    const timer = setTimeout(() => { 
      fetchIndexAmount();
    }, 600);
    return () => clearTimeout(timer);     
  },
    // eslint-disable-next-line
    [quotedTokenAmountBI]
  )

  const { tokenAllowance, tokenBalance } = useMemo(() => { 
    if (!tokensBalances) return { tokenAllowance: 0n, tokenBalance: 0n };

    return {
      tokenAllowance: tokensBalances[quotedToken].allowance,
      tokenBalance: tokensBalances[quotedToken].balance,
    }    
  }, [quotedToken, tokensBalances])

  const { indexErrorMsg, tokenErrorMsg } = useMemo(() => { 
    let indexErrorMsg, tokenErrorMsg;
    let setTokenMetadata = SetTokenMetadata[selectedSetToken];
    if (!isPositiveNumber(inputValue) && inputValue !== "") { 
      return { indexErrorMsg: "Please enter a valid value." }
    }

    if (currentInputType === InputTypes.other && !isPositiveNumber(quotedTokenAmount) && quotedTokenAmount !== "") { 
      return { tokenErrorMsg: "Please enter a valid value." }
    }

    if (currentAction === TradeActions.issue) {
      if (tokenBalance < quotedTokenAmountBI) {
        tokenErrorMsg = `Not enough ${quotedToken.toUpperCase()} to issue ${selectedSetToken.toUpperCase()}.`
      } else if (setTokenPriceData && indexAmountBI > 0) { 
        const totalUSD = parseFloat(formatUnits(indexAmountBI, setTokenMetadata.decimals)) * setTokenPriceData.priceOneSetToken;
        if (totalUSD < 10) {
          indexErrorMsg = "Minimum issue amount is $10.00."
        }
      }
    } else if(userCurrentSetToken) {
      userCurrentSetToken.balance < indexAmountBI && (indexErrorMsg = `Not enough ${selectedSetToken.toUpperCase()} to redeem.`)
    }

    return { indexErrorMsg, tokenErrorMsg }
  },
    // eslint-disable-next-line
    [
      currentAction,
      indexAmountBI,
      indexToken,
      inputValue,
      quotedTokenAmount,
      selectedSetToken,
      tokenBalance,
      userCurrentSetToken
  ])

  const hasLiquidityErrors = useMemo(() => {
    const componentsData = currentAction === TradeActions.issue ? buyComponentsData : sellComponentsData;
    if (!componentsData) return false;

    return Object.keys(componentsData).some((key) => {
      return componentsData[key as SupportedComponents].error !== undefined;
    })

  }, [currentAction, buyComponentsData, sellComponentsData])

  const resetValues = () => { 
    setInputValue("");
    setIndexAmountBI(0n);
    setIndexToken(selectedSetToken)
  }

  const handleIndexAmountChange = async (event: string) => { 
    if (isNumbersOnly(event)) {
      setInputValue(event);
      setIndexAmountBI(Big18Math.fromFloatString(event))
    }
  }

  const handleTokenAmountChange = async (event: string) => { 
    setQuotedTokenAmount(event);
    if (isPositiveNumber(event)) {
      setQuotedTokenAmountBI(parseUnits(event, TokenMetadata[quotedToken].decimals))
    } else {
      setQuotedTokenAmountBI(0n);
    }
  }

  return (
    <Stack
      direction="horizontal"
      gap={3}
      className="crypdex-form align-self-center"
    >
      <div
        className="crypdex-form-mint bg-dark border-primary p-1"
        style={{
          height: currentAction !== TradeActions.info ? "100%" : "30rem",
          width: currentAction !== TradeActions.info ? "30rem" : "45rem"
        }}
      >
        <ul
          className="tabs-indexes mb-3 nav nav-tabs px-0"
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Stack direction="horizontal" gap={0} className="justify-content-between">
            {Object.keys(TradeActions).map((key) => {
              const action = TradeActions[key as keyof typeof TradeActions];
              if (action === TradeActions.config) return <></>;
              return (
                <li className="nav-item" role="presentation">
                  <button
                    key={action}
                    type="button"
                    id={`uncontrolled-tab-example-tab-${action}`}
                    role="tab"
                    data-rr-ui-event-key={action}
                    aria-controls={`uncontrolled-tab-example-tabpane-${action}`}
                    aria-selected={currentAction === action}
                    className={`nav-link ${currentAction === action ? "active" : ""}`}
                    onClick={() => setCurrentAction(action)}
                  >
                    {capitalize(action)}
                  </button>
                </li>
              )
            })}
          </Stack>
        </ul>
        {currentAction !== TradeActions.info ? (
          <Stack className={!isMobile ? "p-3" : "p-2"}>
            {snapshots ? (
              <>
                <Stack direction="vertical" gap={1} className="mb-4">
                  <SetTokensList />
                  <SetTokensComponents />
                  <IndexInput
                    controlId="settoken-input"
                    value={inputValue}
                    title="ARFI Amount"
                    priceOneSetToken={setTokenPriceData?.priceOneSetToken || 0}
                    onFocus={() => setCurrentInputType(InputTypes.index)}
                    onBlur={() => setCurrentInputType(InputTypes.index)}
                    onChange={handleIndexAmountChange}
                    onSetMaxAmount={(amount: bigint) => {
                      setIndexAmountBI(amount);
                      setInputValue(Big18Math.toFloatString(amount));
                    }}
                    isIssuing={currentAction === TradeActions.issue}
                    inputClassName={currentInputType === InputTypes.index && fetchingTransferData ? "text-loading" : ""}
                    disabled={currentInputType === InputTypes.other && fetchingTransferData}
                  />
                  {indexErrorMsg && (
                    <span className="text-red">{indexErrorMsg}</span>
                  )}
                  <TokenInput
                    controlId="token-input"
                    value={quotedTokenAmount}
                    title={currentAction === TradeActions.issue ? "Issue with" : "Receive in"}
                    inputToken={quotedToken}
                    onFocus={() => setCurrentInputType(InputTypes.other)}
                    onBlur={() => setCurrentInputType(InputTypes.index)}
                    setInputToken={(token: SupportedTokens) => setQuotedToken(token)}
                    onChange={(event: string) => handleTokenAmountChange(event)}
                    onSetMaxAmount={(amount: bigint) => {
                      setCurrentInputType(InputTypes.other)
                      setQuotedTokenAmountBI(amount);
                      setQuotedTokenAmount(formatUnits(amount, TokenMetadata[quotedToken].decimals));
                    }}
                    isIssuing={currentAction === TradeActions.issue}
                    inputClassName={currentInputType === InputTypes.index && fetchingTransferData ? "text-loading" : ""}
                    disabled={currentInputType === InputTypes.index && fetchingTransferData}
                  />
                  {tokenErrorMsg && (
                    <span className="text-red">{tokenErrorMsg}</span>
                  )}
                </Stack>
                <TxButton
                  setToken={selectedSetToken as SupportedSetTokens}
                  indexAmountBI={indexAmountBI}
                  quotedToken={quotedToken}
                  quotedTokenAmountBI={quotedTokenAmountBI}
                  buyComponentsData={buyComponentsData}
                  sellComponentsData={sellComponentsData}
                  isIssuing={currentAction === TradeActions.issue}
                  hasAllowance={
                    currentAction === TradeActions.issue ?
                      tokenAllowance >= quotedTokenAmountBI :
                      (userCurrentSetToken?.allowance || 0n) >= indexAmountBI
                  }
                  disabled={!!indexErrorMsg || !!tokenErrorMsg || hasLiquidityErrors || fetchingTransferData}
                  onSuccess={() => resetValues()}
                />
                <SwapLiquidityErrors
                  isIssuing={currentAction === TradeActions.issue}
                  quotedToken={quotedToken}
                  componentsData={currentAction === TradeActions.issue ? buyComponentsData : sellComponentsData}
                />
              </>
            ) : (
              <Stack direction="vertical" className="w-100 p-5 align-items-center justify-content-center">
                <Spinner animation="border" variant="primary" className="normal" />
              </Stack>
            )}
          </Stack>
        ) : (
          <SetTokenInfo />  
        )}
      </div>
    </Stack>
  )
}

const SwapLiquidityErrors = ({
  isIssuing,
  quotedToken,
  componentsData,
}: {
  isIssuing: boolean,
  quotedToken: SupportedTokens,
  componentsData: BuyComponentsDataType | SellComponentsDataType | undefined
}) => {
  const tokenMetadata = TokenMetadata[quotedToken];

  return (
    <Stack direction="vertical" gap={1} className="mt-4">
      {componentsData && Object.keys(componentsData).map((key) => {
        const { error } = componentsData[key as SupportedComponents];
        if (error) {
          const componentMetadata = ComponentMetadata[key as SupportedComponents];
          return (
            <Stack
              key={key}
              className="p-1"
              direction="horizontal"
               gap={2}
              style={{
                border: "0.5px solid #ba1414",
                borderRadius: "5px",
                backgroundColor: "#d615151c",
              }}
            >
              <Stack direction="horizontal" gap={0}>
                <Image src={isIssuing ? tokenMetadata.icon : componentMetadata.icon} height={18} width={18} />
                <Image src={isIssuing ? componentMetadata.icon : tokenMetadata.icon} height={18} width={18} />
              </Stack>
              <span className="text-grey">
                {ParaswapErrorMessages(error)}
              </span>
            </Stack>  
          )
        } else {
          return <></>;
        }
      })}
    </Stack>
  )
}

const TxButton = ({
  setToken,
  indexAmountBI,
  quotedToken,
  quotedTokenAmountBI,
  buyComponentsData,
  sellComponentsData,
  hasAllowance,
  isIssuing,
  disabled,
  onSuccess,
}: {
  setToken: SupportedSetTokens,
  indexAmountBI: bigint,
  quotedToken: SupportedTokens,
  quotedTokenAmountBI: bigint,
  buyComponentsData: BuyComponentsDataType | undefined,
  sellComponentsData: SellComponentsDataType | undefined,
  hasAllowance: boolean,
  isIssuing: boolean,
  disabled: boolean,
  onSuccess: () => void,
}) => {
  const chainId = useChainId();
  const geofence = useGeofenceContext();
  const { onIssueWithExchangeIssuer, onRedeemWithExchageIssuer } = useCrypdexTransactions();
  const [writingTx, setWritingTx] = useState(false);

  const { srcTotalAmountBI, buyExchanges, buyPayloads } = useMemo(() => {
    if (!buyComponentsData) return { srcTotalAmountBI: 0n, buyPayloads: [], buyExchanges: []};

    const totalAmount = Object.keys(buyComponentsData).reduce((acc, key) => {
      return acc + buyComponentsData[key as SupportedComponents].srcAmountBI;
    }, 0n)

    const buyPayloads = Object.keys(buyComponentsData).map((key) => 
      buyComponentsData[key as SupportedComponents].payload
    )

    const buyExchanges = Object.keys(buyComponentsData).map((key) => {
      if (key !== SupportedComponents.floki) {
        return AugustusSwapperAddress[chainId];
      }
      return UniswapV2RouterAddresses[chainId];
    })

    return { srcTotalAmountBI: totalAmount, buyPayloads, buyExchanges };
  }, [chainId, buyComponentsData])

  const { sellExchanges, sellPayloads } = useMemo(() => {
    if (!sellComponentsData) return { sellPayloads: [], sellExchanges: []};

    const sellPayloads = Object.keys(sellComponentsData).map((key) => 
      sellComponentsData[key as SupportedComponents].payload
    )

    const sellExchanges = Object.keys(sellComponentsData).map((key) => {
      if (key !== SupportedComponents.floki) {
        return AugustusSwapperAddress[chainId];
      }
      return FlokiUniswapV2BuyTokensAddresses[chainId];
    })
    
    return { sellPayloads, sellExchanges };
  }, [chainId, sellComponentsData])

  const onIssue = async () => {
    setWritingTx(true);
    const setTokenAddress = ChainSetTokens[chainId][setToken]?.token;
    const srcTokenAddress = ChainTokens[chainId][quotedToken];

    if (setTokenAddress && srcTokenAddress && buyExchanges.length > 0 && buyPayloads.length > 0 ) {
      if (await onIssueWithExchangeIssuer(setTokenAddress, indexAmountBI, srcTokenAddress, srcTotalAmountBI, buyExchanges, buyPayloads)) {
        onSuccess();
      }
    }
    setWritingTx(false);
  }

  const onRedeem = async () => {
    setWritingTx(true);
    const setTokenAddress = ChainSetTokens[chainId][setToken]?.token;
    const destTokenAddress = ChainTokens[chainId][quotedToken];
    if (setTokenAddress && destTokenAddress && sellExchanges.length > 0 && sellPayloads.length > 0 ) {
      if (await onRedeemWithExchageIssuer(setTokenAddress, indexAmountBI, destTokenAddress, sellExchanges, sellPayloads, quotedTokenAmountBI)) {
        onSuccess();
      }
    }
    setWritingTx(false);
  }

  return ( 
    <Stack>
      {hasAllowance ? (
        <Button
          variant="primary"
          style={{ width: "100%" }}
          onClick={() => isIssuing ? onIssue() : onRedeem()}
          disabled={disabled || writingTx || geofence.appDisabled}
        >
          <div className="btn-spinner">
            {writingTx && <Spinner animation="border" variant="secondary" className="small" />}
            {isIssuing ? "Issue" : "Redeem"}
          </div>
        </Button>
      ) : (
        <ApproveButton
          issuing={isIssuing}
          setToken={setToken}
          srcToken={quotedToken}
          amount={isIssuing ? srcTotalAmountBI : indexAmountBI}
          disabled={disabled || geofence.appDisabled}
        />
      )}
    </Stack>
  )
}

const ApproveButton = ({
  issuing,
  setToken,
  srcToken,
  amount,
  disabled
}: {
  issuing: boolean,
  setToken: SupportedSetTokens,
  srcToken: SupportedTokens,
  amount: bigint,
  disabled: boolean,
}) => { 
  const chainId = useChainId();
  const { onApproveSrcToken, onApproveSetToken } = useCrypdexTransactions();
  const [approving, setApproving] = useState(false);

  const { tokenAddress } = useMemo(() => { 
    if (issuing) {
      return { tokenAddress: ChainTokens[chainId][srcToken] }
    }
    return { tokenAddress: ChainSetTokens[chainId][setToken]?.token }
  }, [chainId, issuing, setToken, srcToken])

  const handleOnClick = async () => { 
    setApproving(true);
    if (tokenAddress) {
      if (issuing) {
        const tokenMetadata = TokenMetadata[srcToken];
        await onApproveSrcToken(tokenAddress, amount, tokenMetadata.decimals)
      } else {
        await onApproveSetToken(tokenAddress, amount)
      }
    }
    setApproving(false);
  }

  return (
    <Button
      className="btn btn-primary w-100"
      onClick={() => handleOnClick()}
      disabled={approving || disabled}
    >
      <div className="btn-spinner">
        {approving && <Spinner animation="border" variant="secondary" className="small" />}
        {approving ? (
          `Approving ${issuing ? srcToken.toUpperCase() : setToken.toUpperCase()}`
        ) : (
          `Approve ${issuing ? srcToken.toUpperCase() : setToken.toUpperCase()}`
        )}
      </div>
    </Button>
  )
}
