import React from 'react';
import { Stack } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';


export const SetTokenInfo = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  return (
    <Stack
      direction="vertical"
      gap={4}
      className="settoken-info p-3 w-100 overflow-y-scroll"
      style={{ height: !isMobile ? "25rem" : "30rem" }}
    >
      <h3 className="text-purple">ARFI Index</h3>
      <p>
        ARFI is an equally-weighted basket index comprising the most popular DeFi tokens on the Arbitrum
        blockchain. It offers diversified exposure to the top assets in this dynamic sector.
      </p>
      <Stack direction="vertical" gap={2}>
        <h6 className="text-purple">Criteria:</h6>
        <p>To be included in ARFI, a component must:</p>
        <ol>
          <li>Be classified as a DeFi Token</li>
          <li>Rank in the Top 20 by TVL on Arbitrum according to DeFiLlama</li>
          <li>Have {'>'}$1m in liquidity on a DEX (integrated into paraswap) on the Arbitrum blockchain </li>
        </ol>
      </Stack>
      <Stack direction="vertical" gap={2}>
        <h6 className="text-purple">Components</h6>
        <ul>
          <li>16.6666667% Aave Token (AAVE)</li>
          <li>16.6666667% GMX (GMX)</li>
          <li>16.6666667% Uniswap (UNI)</li>
          <li>16.6666667% Pendle (PENDLE)</li>
          <li>16.6666667% Stargate Token (STG)</li>
          <li>16.6666667% Curve DAO Token (CRV)</li>
        </ul>
      </Stack>
      <Stack direction="vertical" gap={2}>
        <p>
          The quantities of each token required to create the ARFI index were chosen based on the following quantities from the 14th of March:
        </p>
        <ul>
          <li>AAVE: 0.004806 ($0.83)</li>
          <li>GMX: 0.05457 ($0.83)</li>
          <li>UNI: 0.1375 ($0.83)</li>
          <li>PENDLE: 0.3822 ($0.83)</li>
          <li>STG: 4.097 ($0.83)</li>
          <li>CRV: 2.044  ($0.83)</li>
        </ul>
        <p>
          Subsequently, the weights were calculated to achieve a total index price of $5.
        </p>
      </Stack>
      <Stack direction="vertical" gap={2}>
        <h6 className="text-purple">Rebalancing:</h6>
        <p>
          The index is rebalanced monthly at the end of each calendar month.
        </p>
      </Stack>
      <Stack direction="vertical" gap={2}>
        <h6 className="text-purple">Management:</h6>
        <p>
          The Cryptex DAO oversees ARFI, maintaining component weightings and managing inclusions or exclusions to ensure
          the index remains representative of the Arbitrum DeFI market.
        </p>
      </Stack>
      <Stack direction="vertical" gap={2}>
        <h6 className="text-purple">Fees:</h6>
        <p>
          The protocol will apply a streaming fee equivalent to 0.75% APR of the Total Value Locked (TVL) as rebalancing charges.
          The fee charged for managing the ARFI index will be sent to the Cryptex treasury.
        </p>
      </Stack>
    </Stack>
  )
}