import React, { useState } from "react"
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"
import { AiOutlineMenu } from "react-icons/ai"
import { MdOutlineLanguage } from "react-icons/md";
import { useMediaQuery } from "react-responsive"

import { ReactComponent as CryptexLogo } from "../assets/logo.svg"
import { ReactComponent as CryptexMobileLogo } from "../assets/cryptex.svg"
import { CustomConnectButton } from "./CustomConnectButton"
import { LanguageDropdown, LanguageModal } from "./common/Localization"
import { LanguageMetadata, SupportedLanguage } from "../constants/localization"
import { PayWithCoinbaseButton } from "./PayCoinBaseButton";
import { useAddress } from "../hooks/network";
import { useGeofenceContext } from "../contexts";


const Header = () => {
  const { t, i18n } = useTranslation()
  const addressInfo = useAddress();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const isTablet = useMediaQuery({ query: "(min-width: 601px) and (max-width: 850px)" })
  const geofence = useGeofenceContext()
  const currentLanguage = (i18n.resolvedLanguage || SupportedLanguage.en) as SupportedLanguage
  const [showLangModal, setShowLangModal] = useState(false)

  const onModalHide = () => {
    setShowLangModal(false)
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand href="/">
          {isMobile ? <CryptexMobileLogo className="logo" width={32} height={32} /> : <CryptexLogo className="logo" />}
        </Navbar.Brand>
        <Navbar.Collapse id="navbar-nav">
          <Nav className="menu-container">
            <div className="menu">
              <NavDropdown id="trade-dropdown" title="Indexes">
                <NavDropdown.Item href="/">
                  TCAP 2.0
                </NavDropdown.Item>
                <NavDropdown.Item href="/arfi">
                  ARFI
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/spot">
                Spot
              </Nav.Link>
              <NavDropdown id="trade-dropdown" title={"Perpetuals"}>
                <NavDropdown.Item href="/perpetuals">
                  {t("trade")}
                </NavDropdown.Item>
                <NavDropdown.Item href="/perpetuals/liquidity">
                  {t("provide")}
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/governance">
                CTX Governance
              </Nav.Link>
              {isMobile && (
                <>
                  {!!addressInfo.address && <PayWithCoinbaseButton />}
                  <Navbar.Toggle onClick={() => setShowLangModal(true)} className="nav-btn-language">
                    <MdOutlineLanguage size={26} className="pe-1" />
                    {LanguageMetadata[currentLanguage].name}
                  </Navbar.Toggle>
                </>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
        <Nav.Item className="navbar-right">
          {geofence.appDisabled && !isMobile ? (
            <>
              <div className="connect">
                <button className="btn btn-primary" type="button" disabled>
                  {geofence.isUsingProxy() || geofence.isUsingVpn()
                    ? "You are using a VPN or Proxy"
                    : t("app-not-available")
                  }
                </button>
              </div>
              {(isTablet || isMobile) && (
                <Navbar.Toggle
                  aria-controls="navbar-nav"
                  children={
                    <AiOutlineMenu size={18} fontWeight={700} />
                  }  
                />    
              )}
            </>
          ) : (
            <>
              {(!geofence.isRestrictedCountry()) && (
                <div className="connect">
                  <CustomConnectButton />
                </div>
              )}
              {isTablet && <LanguageDropdown />}  
              <Navbar.Toggle
                aria-controls="navbar-nav"
                children={
                  <AiOutlineMenu size={24} fontWeight={700} />
                }
              />
            </>
          )}
          {!isTablet && !isMobile && <LanguageDropdown />}
          {!isMobile && !!addressInfo.address && <PayWithCoinbaseButton />}
        </Nav.Item>
      </Container>
      <LanguageModal show={isMobile && showLangModal} onHide={onModalHide} />
    </Navbar>
  )
};

export default Header
