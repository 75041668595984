import React, { useMemo } from "react"
import { Image, Stack } from "react-bootstrap"

import { useCrypdexContext } from "../../../contexts/crypdexContext";
import { ComponentMetadata, SetTokenMetadata } from "../../../constants/crypdex";
import { CustomTooltip } from "../../common";


export const SetTokensComponents = () => { 
  const { selectedSetToken } = useCrypdexContext();
  const setTokenMetadata = useMemo(() => SetTokenMetadata[selectedSetToken], [selectedSetToken]);
    
  return (
    <Stack
      direction="horizontal"
      gap={2}
      className="px-2 py-2"
      style={{
        backgroundColor: "#131212",
        border: "0.5px solid #281e31",
        borderRadius: "2px",
      }}
    >
      
      {setTokenMetadata.components.map((component, index) => {
        const token = ComponentMetadata[component];
        return (
          <CustomTooltip
            key={index}
            id={`tooltip-c-${index}`}
            msg={token.name}
            showIcon={false}
          >
            <Image
              src={token.icon}
              height={24}
              width={24}
              style={{
                //background: "#281e31",
                //borderRadius: "50%",
                //padding: "1px"
              }}
            />
          </CustomTooltip>
        )
      })}   
    </Stack> 
  )
}