import React, { useMemo }  from "react";
import { Button, Dropdown, Form, Image, InputGroup, Stack } from "react-bootstrap/esm";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { TokenMetadata, SupportedTokens } from "../../../constants/tokens";
import { useTokensBalances } from "../../../hooks/crypdex";
import { formatUnits } from "viem";
import { IndexProducts } from "../../../constants/indexes";


type props = {
  controlId: string;
  value: string;
  title: string;
  inputToken: SupportedTokens;
  onFocus: () => void;
  onBlur: () => void;
  setInputToken: (token: SupportedTokens) => void;
  onChange: (event: string) => void;
  onSetMaxAmount: (amount: bigint) => void;
  isIssuing: boolean;
  inputClassName?: string;
  disabled?: boolean;
}

export const TokenInput = ({
  controlId,
  value,
  title,
  inputToken,
  onFocus,
  onBlur,
  setInputToken,
  onChange,
  onSetMaxAmount,
  isIssuing,
  inputClassName,
  disabled,
}: props) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const tokenMetadata = TokenMetadata[inputToken];
  const { data: tokensBalances } = useTokensBalances();

  const { maxValue, maxValueBI } = useMemo(() => {
    if (!tokensBalances ) return { maxValue: 0, maxValueBI: 0n };

    return {
      maxValue: parseFloat(formatUnits(tokensBalances[inputToken].balance, tokenMetadata.decimals)),
      maxValueBI: tokensBalances[inputToken].balance,
    };
  },
    // eslint-disable-next-line
    [inputToken, tokensBalances]
  );

  return (
    <Form.Group className="asset-input-group" style={{ marginTop: "0.5rem", marginBottom: "0.1rem" }}>
      <div className="asset-input-top">
        <h6>{title}</h6>
      </div>
      <InputGroup className="input-collateral">
        <Form.Control
          id={controlId}
          placeholder="0"
          className={`balance-input ${inputClassName}`}
          value={value}
          type={isMobile ? "number" : "text"}
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)
          }
          onFocus={() => onFocus()}
          onBlur={() => onBlur()}
          disabled={disabled}
        />
        <div className="asset-info">
          <div className="asset-info2">
            <Dropdown
              className="markets-dropdown"
              onSelect={(eventKey) => setInputToken((eventKey || tokenMetadata.symbol) as SupportedTokens)}
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                variant="success"
                className="dropdown-toggle-small"
                style={{ width: "6.5rem", borderRadius: "5px", padding: "0.2rem 0.5rem"}}
              >
                <Stack direction="horizontal" gap={1}>
                  <Image src={tokenMetadata.icon} height={15} width={15} />
                  <span style={{ fontSize: "0.85rem" }}>
                    {tokenMetadata.symbol}
                  </span>  
                </Stack>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-small">
                {Object.keys(SupportedTokens).map((token) => {
                  if (token === SupportedTokens.cbbtc || token === SupportedTokens.lbtc) return null;

                  const tokenMetadata = TokenMetadata[token as SupportedTokens];
                  if (!tokenMetadata.products.includes(IndexProducts.oneToOne)) return <></>

                  return (
                    <Dropdown.Item key={`di-${token}`} eventKey={tokenMetadata.symbol.toLocaleLowerCase()}>
                      <Stack direction="horizontal" gap={2}>
                        <Image src={tokenMetadata.icon} height={16} width={16} />
                        <span style={{ fontSize: "0.9rem" }}>
                          {tokenMetadata.symbol}
                        </span>
                      </Stack>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="asset-balance">
            <span className="title">{t("balance")}:</span>
            <span className="balance">{maxValue.toFixed(4)}</span>
            {isIssuing && (
              <Button
                variant="secondary"
                className="small"
                onClick={() => onSetMaxAmount(maxValueBI || 0n)}
              >
                {t("max")}
              </Button>
            )}
          </div>
        </div>
      </InputGroup>
    </Form.Group>
  );    
}

