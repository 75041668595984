import React from "react"
import { useTranslation } from "react-i18next"
import logo from "../../assets/logo.svg"
// import mainVideoWebm from "../../assets/videos/main.webm"

export const LoadingPage = () => {
  const { t } = useTranslation()

  return (
    <div className="loading-page" style={{ marginTop: "-50px"}}>
      <div className="loading-page-container">
        <img
          src={logo}
          alt="cryptex logo"
          className="breathing-icon"
          height="70"
        />
        <h3 className="bold">{t("loading")}...</h3>
      </div>
    </div>
  )
}
