import React, { useMemo } from 'react';
import { Dropdown, Image, Spinner, Stack } from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";
import { SetTokenMetadata, SupportedSetTokens } from '../../../constants/crypdex';
import { useCrypdexContext } from '../../../contexts/crypdexContext';
import { capitalize } from '../../../utils/utils';
import { useSetTokenPrice } from '../../../hooks/crypdex';


export const SetTokensList = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const { selectedSetToken, setSelectedSetToken, snapshots } = useCrypdexContext();
  const { data: tokenPriceData } = useSetTokenPrice(selectedSetToken);
  const currentSetTokenMetadata = SetTokenMetadata[selectedSetToken];

  const setTokens = useMemo(() => {
    if (!snapshots) return;
    return snapshots.setTokens;
  }, [snapshots])

  return (
    <>
      <Dropdown
        className="markets-dropdown settokens"
        onSelect={(eventKey) => setSelectedSetToken(eventKey as SupportedSetTokens)}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          variant="success"
          disabled={true}
          className={isMobile ? "ps-0 pe-1" : "ps-1"}
        >
          <div className="dropdown-toggle-container ps-1">
            <div className="toggle-section market" style={{ width: "58%" }}>
              <Image src={currentSetTokenMetadata.icon} height={42} width={42} />
              <div className={`market-desc ${isMobile ? "ps-1" : "ps-2"}`}>
                <h6 className="title">
                  {capitalize(currentSetTokenMetadata.symbol)}
                </h6>
                <span className="caption" >{currentSetTokenMetadata.name}</span>
              </div>
            </div>
            <div className="vertical-divisor" />
            <div className="toggle-section price-box" style={{ width: !isMobile ? "42%" : "35%" }}>
              <div className="flex-column">
                {tokenPriceData ? (
                  <span className={"price text-green"}>
                    {`$${tokenPriceData.priceOneSetToken.toFixed(4)}`}
                  </span>
                ) : (
                  <Spinner animation="border" variant="success" />  
                )}
              </div>
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {setTokens && Object.keys(setTokens).map((setToken) => {
            const setTokenMetada = SetTokenMetadata[setToken as SupportedSetTokens]
            return (
              <Dropdown.Item key={`di-${setToken}`}>
                <Stack direction="horizontal" gap={2}>
                  <Image src={setTokenMetada.icon} height={38} width={38} />
                  <div className="market-desc">
                    <h6 className="title">
                      {setTokenMetada.symbol}
                    </h6>
                    <span className="caption">{setTokenMetada.name}</span>
                  </div>
                </Stack>  
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}