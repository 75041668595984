import { useCallback } from "react";
import { useWalletClient } from "wagmi"
import { useQueryClient } from '@tanstack/react-query'
import { Address, BaseError } from "viem";

import { errorNotification, useTransactionToasts } from "../../components/common";
import { getPublicClient, metamaskTxRejectedError } from "../../constants/network";
import { ERC20Abi } from "../../abi/ERC20.abi";
import { useChainId } from "../network";
import { BasicIssuanceModuleAddresses, TokenExchangeSetIssuerAddresses } from "../../constants/crypdex";
import { TokenExchangeSetIssuerAbi } from "../../abi/TokenExchangeSetIssuer.abi";
import { SetTokenAbi } from "../../abi/SetToken.abi";
import { fromFloatString } from "../../utils/bigIntUtils";


export const useApproveAllowance = () => {
  const chainId = useChainId();
  const { data: walletClient } = useWalletClient();

  const onApprove = async (symbol: string, tokenAddress: Address, amount: bigint) => { 
    if (walletClient === undefined) return
    
    const publicClient = getPublicClient(chainId)
    const spender = BasicIssuanceModuleAddresses[chainId]
    try {
      const hash = await walletClient.writeContract({
        address: tokenAddress,
        abi: ERC20Abi,
        functionName: "approve",
        args: [spender, amount],
      })

      await publicClient.waitForTransactionReceipt({ hash })

      return true;
    } catch (err) { 
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification(`Error: Failed to approve ${symbol.toUpperCase()}`)
        }
      } else {
        errorNotification(`Error: Failed to approve ${symbol.toUpperCase()}`)
      }
      return false;
    }
  }

  return {
    onApprove
  }
}

export const useCrypdexTransactions = () => {
  const chainId = useChainId()
  const { data: walletClient } = useWalletClient();
  const queryClient = useQueryClient()
  const toasts = useTransactionToasts()

  const refresh = useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          ['arfiTokenBalances', 'SetTokensBalances', 'SetTokensSnapshot'].includes(queryKey.at(0) as string) &&
          queryKey.includes(chainId),
      }),
    [chainId, queryClient],
  )

  const refreshBalances = useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          ['arfiTokenBalances', 'SetTokensSnapshot', 'SetTokensBalances'].includes(queryKey.at(0) as string) &&
          queryKey.includes(chainId),
      }),
    [chainId, queryClient],
  )

  const onIssueWithExchangeIssuer = async (
    setToken: Address,
    setTokenAmount: bigint,
    srcToken: Address,
    srcAmount: bigint,
    exchanges: Array<Address>,
    payload: Array<`0x${string}`>
  ) => { 
    if (walletClient === undefined) return

    try {
      const publicClient = getPublicClient(chainId)
      const contractAddress = TokenExchangeSetIssuerAddresses[chainId]

      const { request } = await publicClient.simulateContract({
        address: contractAddress,
        abi: TokenExchangeSetIssuerAbi,
        functionName: "buyComponentsAndIssueSetToken",
        args: [setToken, setTokenAmount, srcToken, srcAmount, exchanges, payload],
        account: walletClient.account.address,
      })
      console.log("Buy Request: ", request)

      /* console.log("test 33: ")
      await walletClient.sendTransaction({
        to: exchanges[3],
        data: payload[3],
      }) */
      
      const hash = await walletClient.writeContract(request)
      await toasts.waitForTransactionAlert(hash,
        {
          successMsg: "Token issued successfully",
          errorMsg: "Failed to issue",
          onSuccess: async () => {
            await refresh()
            setTimeout(() => refresh(), 7000)
          }
        }
      )
      return true;
    } catch (err) {
      console.log("Issue error: ", err)
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to issue")
        }
      } else {
        errorNotification("Error: Failed to issue")
      }
      return false;
    }
  }

  const onRedeemWithExchageIssuer = async (
    setToken: Address,
    setTokenAmount: bigint,
    destToken: Address,
    exchanges: Array<Address>,
    payload: Array<`0x${string}`>,
    quotedTokenAmountBI: bigint,
  ) => {
    if (walletClient === undefined) return

    try {
      const publicClient = getPublicClient(chainId)
      const contractAddress = TokenExchangeSetIssuerAddresses[chainId]
      const minQuoteAmount = (quotedTokenAmountBI * 97n) / 100n

      const { request } = await publicClient.simulateContract({
        address: contractAddress,
        abi: TokenExchangeSetIssuerAbi,
        functionName: "redeemSetTokenAndExchangeTokens",
        args: [setToken, setTokenAmount, destToken, minQuoteAmount, exchanges, payload],
        account: walletClient.account.address,
      })

      const hash = await walletClient.writeContract(request)
      await toasts.waitForTransactionAlert(hash,
        {
          successMsg: "Token redeemed successfully",
          errorMsg: "Failed to redeem",
          onSuccess: async () => {
            await refresh()
            setTimeout(() => refresh(), 7000)
          }
        }
      )
      return true;
    } catch (err) {
      console.log("Redeem error: ", err)
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to redeem")
        }
      } else {
        errorNotification("Error: Failed to redeem")
      }
      return false;
    }
  }

  const onApproveSrcToken = async (srcToken: Address, amount: bigint, decimals: number) => { 
    if (walletClient === undefined) return
    
    const spender = TokenExchangeSetIssuerAddresses[chainId]
    try {
      const publicClient = getPublicClient(chainId)
      const { request } = await publicClient.simulateContract({
        address: srcToken,
        abi: ERC20Abi,
        functionName: "approve",
        args: [spender, amount + fromFloatString("1.05", decimals)],
        account: walletClient.account.address,
      })
      
      const hash = await walletClient.writeContract(request)

      await toasts.waitForTransactionAlert(hash,
        {
          successMsg: "Token approved successfully",
          errorMsg: "Failed to approve Token",
          onSuccess: async () => {
            await refreshBalances()
            setTimeout(() => refreshBalances(), 5000)
          }
        }
      )

      return true;
    } catch (err) { 
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to approve")
        }
      } else {
        errorNotification("Error: Failed to approve")
      }
      return false;
    }
  }

  const onApproveSetToken = async (setToken: Address, amount: bigint) => { 
    if (walletClient === undefined) return
    
    const spender = TokenExchangeSetIssuerAddresses[chainId]
    try {
      const publicClient = getPublicClient(chainId)
      const { request } = await publicClient.simulateContract({
        address: setToken,
        abi: SetTokenAbi,
        functionName: "approve",
        args: [spender, amount + BigInt("1000")],
        account: walletClient.account.address,
      })

      const hash = await walletClient.writeContract(request)

      await toasts.waitForTransactionAlert(hash,
        {
          successMsg: "Token approved successfully",
          errorMsg: "Failed to approve Token",
          onSuccess: async () => {
            await refreshBalances()
            setTimeout(() => refreshBalances(), 6500)
          }
        }
      )

      return true;
    } catch (err) { 
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to approve")
        }
      } else {
        errorNotification("Error: Failed to approve")
      }
      return false;
    }
  }

  return {
    onIssueWithExchangeIssuer,
    onRedeemWithExchageIssuer,
    onApproveSrcToken,
    onApproveSetToken,
  }
}
