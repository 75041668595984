import React from "react";
import { Button, Modal } from "react-bootstrap/esm";

type props = {
  showModal: boolean | undefined;
  onHide: () => void;
};

const TermsOfServiceModal = ({ showModal, onHide }: props) => {

  const handleButtonClick = () => {
    localStorage.setItem('tosAccepted2', 'true');
    onHide();
  }

  const url_path = window.location.pathname;
  const tos_url = "/terms-of-service";

  return (
    <>
      {url_path !== tos_url && (
        <Modal show={showModal} onHide={onHide} backdrop="static" className="modal-tos mt-0">
          <Modal.Header>
            <Modal.Title>Terms of Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>
                You are entering app.cryptex.finance, a community deployed and maintained
                instance of the open source Cryptex front end.
              </p>
              <br/>
              <p>
                By clicking Agree you accept the{" "}
                <a href="/terms-of-service" target="_blank">Terms and Conditions.</a>
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleButtonClick} className="tos-button">
              Agree and continue
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default TermsOfServiceModal;
