export const TokenExchangeSetIssuerAbi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "target",
                "type": "address"
            }
        ],
        "name": "AddressEmptyCode",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "AddressInsufficientBalance",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ETHNotAccepted",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ExchangeCallFailed",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ExchangeLengthMismatch",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "FailedInnerCall",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "FunctionNotWhitelisted",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "InvalidPayload",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "IssuanceAddressCannotBeZero",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "OwnableInvalidOwner",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "OwnableUnauthorizedAccount",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "PayloadLengthMismatch",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "QuantityBoughtLessThanMinimum",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "QuoteAmountCannotBeZero",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ReceivedAmountLessThanExpected",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ReentrancyGuardReentrantCall",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "token",
                "type": "address"
            }
        ],
        "name": "SafeERC20FailedOperation",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "SetAddressCannotBeZero",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "SetQuantityCannotBeZero",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "SetTokenNotWhitelisted",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TargetAddressCannotBeZero",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "target",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bytes4",
                "name": "selector",
                "type": "bytes4"
            }
        ],
        "name": "FunctionRevoked",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "target",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bytes4",
                "name": "selector",
                "type": "bytes4"
            }
        ],
        "name": "FunctionWhitelisted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "setToken",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "issuanceModule",
                "type": "address"
            }
        ],
        "name": "IssuanceModuleAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "setToken",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "issuanceModule",
                "type": "address"
            }
        ],
        "name": "IssuanceModuleRemoved",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "setToken",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "issuanceModule",
                "type": "address"
            }
        ],
        "name": "addSetTokenIssuanceModules",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract IERC20[]",
                "name": "tokens",
                "type": "address[]"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "approveTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract ISetToken",
                "name": "setToken",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "setTokenQuantity",
                "type": "uint256"
            },
            {
                "internalType": "contract IERC20",
                "name": "quoteAsset",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "totalQuoteAmount",
                "type": "uint256"
            },
            {
                "internalType": "address[]",
                "name": "exchanges",
                "type": "address[]"
            },
            {
                "internalType": "bytes[]",
                "name": "exchangePayloads",
                "type": "bytes[]"
            }
        ],
        "name": "buyComponentsAndIssueSetToken",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "extraQuoteBalance",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract IERC20",
                "name": "token",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "recoverTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract ISetToken",
                "name": "setToken",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "setTokenQuantity",
                "type": "uint256"
            },
            {
                "internalType": "contract IERC20",
                "name": "quoteAsset",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "minQuoteAmount",
                "type": "uint256"
            },
            {
                "internalType": "address[]",
                "name": "exchanges",
                "type": "address[]"
            },
            {
                "internalType": "bytes[]",
                "name": "exchangePayloads",
                "type": "bytes[]"
            }
        ],
        "name": "redeemSetTokenAndExchangeTokens",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "quoteAssetBalanceAfterSell",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "setToken",
                "type": "address"
            }
        ],
        "name": "removeSetTokenIssuanceModules",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "target",
                "type": "address"
            },
            {
                "internalType": "bytes4[]",
                "name": "selectors",
                "type": "bytes4[]"
            }
        ],
        "name": "revokeWhitelistedFunctions",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "setTokenIssuanceModules",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "target",
                "type": "address"
            },
            {
                "internalType": "bytes4[]",
                "name": "selectors",
                "type": "bytes4[]"
            }
        ],
        "name": "whitelistFunctions",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes24",
                "name": "",
                "type": "bytes24"
            }
        ],
        "name": "whitelistedFunctions",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "stateMutability": "payable",
        "type": "receive"
    }
] as const;
