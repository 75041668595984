import React, { useMemo } from "react";
import { Button, ButtonGroup, Dropdown, DropdownButton, Image, Spinner, Stack } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next'
import { formatUnits } from "viem";

import { useIndexVaultContext } from "../../../contexts/indexVaultContext";
import { useChainId } from "../../../hooks/network";
import { IndexMetadata, SupportedIndexes } from "../../../constants/indexes";
import { IndexVaultsMetadata, SupportedPockets, VaultType } from "../../../constants/vaults";
import { SupportedTokens, TokenMetadata } from "../../../constants/tokens";
import { VaultStatus, VaultStatusColor, VaultTxList } from "../constants";
import { getStatusFromRatioBI } from "../utils";
import { CustomTooltip } from "../../common";
import { formatBigIntUSDPrice } from "../../../utils/bigIntUtils";
import { capitalize } from "../../../utils/utils";
import { IndexPrices } from "./IndexPrices";
import { useGeofenceContext } from "../../../contexts";


export const VaultsMenu = ({ showForm } : { showForm: (isUpdating: boolean, baseAction: VaultTxList, canChoosePocket: boolean) => void }) => {
  const { t } = useTranslation()
  const geofence = useGeofenceContext();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const chainId = useChainId();
  const vaults = IndexVaultsMetadata[chainId][SupportedIndexes.tcap];
  const { selectedIndex, setSelectedPocket, setSelectedVaultByCollateral, userSnapshots, vaultSnapshots } = useIndexVaultContext();
  const indexMetadata = IndexMetadata[selectedIndex];

  const hasVaults = useMemo(() => { 
    if (!userSnapshots) return false;

    // console.log(userSnapshots)
    let vaultCount = 0;
    const indexes = Object.keys(userSnapshots) as SupportedIndexes[];
    for (let i = 0; i < indexes.length; i++) {
      const vaults = userSnapshots[indexes[i]];
      for (let j = 0; j < vaults.length; j++) {
        if (parseFloat(formatUnits(vaults[j].collateralAmountUsdc, 18)) > 0.01) {
          vaultCount++;
        }
      }
    }

    return vaultCount > 0;
  }, [userSnapshots])

  return (
    <Stack
      direction="vertical"
      gap={2}
      className={`vaults-menu align-self-center align-items-center pb-4 mt-5 mb-5 ${hasVaults ? 'w-75' : 'w-50'}`}
      style={{ flex: "auto" }}
    >
      <Stack direction="vertical" gap={2} style={{ flex: "unset" }}>
        <Stack direction="horizontal" gap={2} className="justify-content-center">
          <Image src={indexMetadata.icon} height={34} width={34} />
          <h2>{t("index-vaults", { token: "TCAP" })}</h2>
        </Stack>
        <IndexPrices />
      </Stack>  
      {vaultSnapshots ? (
        <Stack direction="vertical" gap={1} className="w-95 mt-3 py-1 px-0 align-self-center" style={{ flex: "auto" }}>
          {hasVaults && !isMobile && (
            <Stack direction="horizontal" gap={2} className="w-100 align-items-center justify-content-between py-0 px-2">
              <Stack direction="horizontal" gap={2} className="w-10">
                <h6 className="text-muted small">{t("collateral")}</h6>
              </Stack>
              <Stack direction="horizontal" gap={2} className="w-16 justify-content-end">
                <h6 className="text-muted small">{t("price")}</h6>
              </Stack>
              <Stack direction="horizontal" gap={2} className="w-17 justify-content-end">
                <h6 className="text-muted small">{t("deposited")}</h6>
              </Stack>
              <Stack direction="horizontal" gap={2} className="w-15 justify-content-end">
                <h6 className="text-muted small">{t("minted")}</h6>
              </Stack>
              <Stack direction="horizontal" gap={2} className="w-11 justify-content-end">
                <CustomTooltip
                  id="tt-vratio"
                  msg={t("tooltip.ratio")}
                  showIcon={true}
                  iconOnLeft={true}
                  placement="top"
                  iconSize={12}
                  iconColor="#9795b0"
                >
                  <h6 className="text-muted small ps-1">{t("ratio")}</h6>
                </CustomTooltip>
              </Stack>
              <Stack direction="horizontal" gap={2} className="w-11 justify-content-end">
                <h6 className="text-muted small">Aave APY</h6>
              </Stack>
              <Stack direction="horizontal" className="w-20 justify-content-end" />
            </Stack>
          )}
          {!hasVaults && (
            <Stack direction="horizontal" gap={2} className="w-100 align-items-center justify-content-between py-0 px-2">
              <Stack direction="horizontal" gap={2} className="w-30">
                <h6 className="text-muted small">{t("collateral")}</h6>
              </Stack>
              <Stack direction="horizontal" className="w-30 justify-content-end">
                <h6 className="text-muted small">{t("price")}</h6>
              </Stack>
              <Stack className="w-40" />
            </Stack>
          )}
          {hasVaults && vaults.map((vault, index) => {
            return (
              Object.keys(vault.pockets).map((pocket) => {
                if (!vault.pockets[pocket as SupportedPockets]) return <></>
                return (
                  <VaultItem
                    key={`vault-${index}-${pocket}`}
                    row={index}
                    vault={vault}
                    pocketType={pocket as SupportedPockets}
                    showForm={(isUpdating, baseAction, canChoosePocket) => showForm(isUpdating, baseAction, canChoosePocket)}
                  />
                )
              })
            )
          })}
          {!hasVaults && vaults.map((vault, index) => {
            const collateral = TokenMetadata[vault.collateral]
            const snapshots = vaultSnapshots && vaultSnapshots[selectedIndex];
            if (!snapshots) return <></>;

            const vaultSnapshot = snapshots.find((s) => s.collateralToken === collateral.symbol.toLowerCase());
            return (
              <Stack
                key={`vault-${index}`}
                direction="horizontal"
                gap={2}
                className={`w-100 align-items-center justify-content-between p-2 ${index % 2 === 0 ? 'border-box' : 'border-box-light'}`}
              >
                <Stack direction="horizontal" gap={2} className="w-30">
                  <Image src={collateral.icon} height={22} width={22} />
                  <span>{collateral.symbol.toUpperCase()}</span>
                </Stack>
                <Stack direction="horizontal" gap={2} className="w-30 justify-content-end">
                  <span>
                    {formatBigIntUSDPrice(vaultSnapshot?.collateralPrice || 0n, 18)}
                  </span>
                </Stack>
                <Stack direction="horizontal" gap={1} className="w-40 justify-content-end">
                  <Button
                    onClick={() => {
                      setSelectedVaultByCollateral(vault.collateral);
                      setSelectedPocket(!!vault.pockets.aave ? SupportedPockets.aave : SupportedPockets.default);
                      showForm(false, VaultTxList.depositMint, true);
                    }}
                    disabled={geofence.appDisabled}
                    style={{ border: "0.5px solid #A440F2" }}
                  >
                    {t("mint-index", { index: "TCAP" })}
                  </Button>
                </Stack>
              </Stack>
            )
          })}
        </Stack>
      ) : (
        <div className="spinner-container mt-4 pt-5">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </Stack>
  );    
}

const VaultItem = ({
  row,
  vault,
  pocketType,
  showForm
}: {
  row: number,
  vault: VaultType,
  pocketType: SupportedPockets,
  showForm: (isUpdating: boolean, baseAction: VaultTxList, canChoosePocket:boolean) => void
}) => {
  const { t } = useTranslation()
  const geofence = useGeofenceContext();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const collateral = TokenMetadata[vault.collateral]
  const tokenMetadata = TokenMetadata[vault.collateral];
  const { selectedIndex, setSelectedVaultByCollateral, setSelectedPocket, vaultSnapshots, userSnapshots } = useIndexVaultContext();
  const indexMetadata = IndexMetadata[selectedIndex]

  const { userVault, vaultStatus, aaveApy, collateralPrice, showOpenPocketButton } = useMemo(() => {
    let userVault = undefined;
    let vaultSnapshot = undefined;
    let showOpenPocketButton = false;
  
    if (userSnapshots) {
      const userVaults = userSnapshots[selectedIndex].filter((snapshot) => {
        return snapshot.collateralToken === vault.collateral &&
          snapshot.pocket === pocketType &&
          parseFloat(formatUnits(snapshot.collateralAmountUsdc, 18)) > 0.01
      });
      userVault = userVaults.length > 0 ? userVaults[0] : undefined;
      
      // checks if has vault in other pocket
      if (vault.collateral !== SupportedTokens.lbtc) {
        const otherPocket = pocketType === SupportedPockets.aave ? SupportedPockets.default : SupportedPockets.aave;
        const otherVaults = userSnapshots[selectedIndex].filter((snapshot) => {
          return snapshot.collateralToken === vault.collateral &&
            snapshot.pocket === otherPocket &&
            parseFloat(formatUnits(snapshot.collateralAmountUsdc, 18)) > 0.01
        });
        showOpenPocketButton = otherVaults.length === 0;
      } else {
        showOpenPocketButton = true;
      }
    }
    if (vaultSnapshots) {
      vaultSnapshot = vaultSnapshots[selectedIndex].find((snapshot) => snapshot.collateralToken === vault.collateral);
    }

    return {
      userVault,
      vaultStatus: userVault && vaultSnapshot
        ? getStatusFromRatioBI(vaultSnapshot.liquidationParams.threshold, userVault.healthFactor)
        : VaultStatus.safe,
      aaveApy: userVault?.pocket === SupportedPockets.aave ? `${((vaultSnapshot?.aaveApy || 0) * 100).toFixed(2)}%` : "N/A",
      collateralPrice: vaultSnapshot?.collateralPrice || 0n,
      showOpenPocketButton,
    }
  }, [selectedIndex, vault.collateral, pocketType, userSnapshots, vaultSnapshots])

  if (!userVault && !showOpenPocketButton) return <></>;
  if (!userVault && showOpenPocketButton && pocketType === SupportedPockets.aave) return <></>;

  return (
    <Stack
      direction={!isMobile ? "horizontal" : "vertical"}
      gap={1}
      className={`index-vault w-100 align-items-center justify-content-between p-2 ${row % 2 === 0 || isMobile ? 'border-box' : 'border-box-light'}`}
    >
      <Stack direction="horizontal" className={`index-vault-mobile-header ${!isMobile ? "w-10" : "w-100"}`}>
        <Stack direction="horizontal" gap={2} className="w-100">
          <Image src={collateral.icon} height={22} width={22} />
          <span style={{ fontSize: !isMobile ? "1.3rem" : "1rem" }}>
            {collateral.symbol.toUpperCase()}
          </span>
        </Stack>
      </Stack> 
      <Stack direction="horizontal" className="w-70 index-vault-mobile-body">
        {!isMobile && (
          <Stack direction="horizontal" className="w-22 vault-item align-items-center justify-content-end">
            <h6>{formatBigIntUSDPrice(collateralPrice, 18)}</h6>
          </Stack>
        )}
        <Stack direction="horizontal" className="w-25 vault-item align-items-center justify-content-end" gap={1}>
          <Stack direction="vertical" className="w-100 vault-item-inner justify-content-center" gap={1}>
            {userVault ? (
              <CustomTooltip
                id="tt-vcollateral"
                msg={formatBigIntUSDPrice(userVault.collateralAmountUsdc, 18)}
                showIcon={false}
                iconOnLeft={true}
                placement="top"
              >
                <>
                  <h6 className="pe-1 text-decoration-dotted text-decoration-muted">
                    {parseFloat(formatUnits(userVault.collateral, tokenMetadata.decimals)).toFixed(4)}
                  </h6>
                  <Image className="token-icon" src={tokenMetadata.icon} alt={indexMetadata.name} height={18} width={18} />
                </>
              </CustomTooltip>
            ) : (
              <h6 className="pe-2 text-right">-</h6>
            )}
            {isMobile && <h6 className="only-mobile">{t("deposited")}</h6>}
          </Stack>  
        </Stack>
        <Stack direction="horizontal" className="w-23 vault-item right align-items-center justify-content-end" gap={1}>
          <Stack direction="vertical" className="w-100 vault-item-inner justify-content-center" gap={1}>
            {userVault ? (
              <CustomTooltip
                id="tt-vcollateral"
                msg={formatBigIntUSDPrice(userVault.mintedAmountUsdc, 18)}
                showIcon={false}
                iconOnLeft={true}
                placement="top"
              >
                <>
                  <h6 className="pe-1 text-decoration-dotted text-decoration-muted">
                    {parseFloat(formatUnits(userVault.mintedAmount || 0n, indexMetadata.decimals)).toFixed(4)}
                  </h6>
                  <Image className="token-icon" src={indexMetadata.icon} alt={indexMetadata.name} height={20} width={20} />
                </>
              </CustomTooltip>  
            ) : (
              <h6 className="pe-2 text-right">-</h6>
            )}
            {isMobile && <h6 className="only-mobile">{t("minted")}</h6>}
          </Stack>  
        </Stack>
        <Stack direction="horizontal" className="w-15 vault-item align-items-center justify-content-end">
          <Stack direction="vertical" className="w-100 vault-item-inner justify-content-center" gap={1}>
            {(userVault?.mintedAmount || 0n) > 10000n ? (
              <h6 className="text-right" style={{ color: VaultStatusColor[vaultStatus] }}>
                {(parseFloat(formatUnits(userVault?.healthFactor || 0n, 18)) * 100).toFixed(1)}%
              </h6>
            ) : (
              <h6 className="pe-2 text-right">-</h6>
            )}
            {isMobile && <h6 className="only-mobile">{t("ratio")}</h6>}
          </Stack>  
        </Stack>
        <Stack direction="horizontal" className="w-15 vault-item right align-items-center justify-content-end">
          <Stack direction="vertical" className="w-100 vault-item-inner justify-content-center" gap={1}>
            {userVault ? (
              <h6 className={`text-right ${aaveApy === "N/A" ? "text-muted" : "text-green"}`}>{aaveApy}</h6>
            ) : (
              <h6 className="pe-2 text-right">-</h6>  
            )}
            {isMobile && <h6 className="only-mobile">Aave Apy</h6>}
          </Stack>  
        </Stack>
      </Stack>  
      <Stack direction="horizontal" gap={1} className="w-20 index-vault-mobile-footer justify-content-end">
        {userVault ? (
          <DropdownButton
            as={ButtonGroup}
            title={t("manage")}
            id="bg-nested-dropdown"
            disabled={geofence.appDisabled}
            onSelect={(eventKey) => {
              setSelectedVaultByCollateral(vault.collateral);
              if (eventKey !== "openVault") {
                setSelectedPocket(userVault.pocket);
                showForm(true, eventKey as VaultTxList, false);
              } else { 
                setSelectedPocket(pocketType === SupportedPockets.aave ? SupportedPockets.default : SupportedPockets.aave);
                showForm(false, VaultTxList.depositMint, false);
              }
            }}
            style={{ width: !isMobile ? "8rem" : "100%", borderColor: "#a440f2" }}
          >
            <Dropdown.Item eventKey={VaultTxList.depositMint} style={{ height: "2.7rem" }}>
              {`${t("deposit")} / ${t("mint")}`}
            </Dropdown.Item>
            <Dropdown.Item eventKey={VaultTxList.burnWithdraw} style={{ height: "2.7rem" }}>
              {`${t("withdraw")} / ${t("burn")}`}
            </Dropdown.Item>
            {showOpenPocketButton && vault.collateral !== SupportedTokens.lbtc  && (
              <Dropdown.Item eventKey="openVault" style={{ height: "2.7rem" }}>
                {t("open-vault-type", {
                  type: pocketType === SupportedPockets.aave ? capitalize(SupportedPockets.default) : capitalize(SupportedPockets.aave)
                })}
              </Dropdown.Item>
            )}
          </DropdownButton>
        ) : (
          <Button
            onClick={() => {
              setSelectedVaultByCollateral(vault.collateral);
              setSelectedPocket(!!vault.pockets.aave ? SupportedPockets.aave : SupportedPockets.default);
              showForm(false, VaultTxList.depositMint, true);
            }}
            style={{
              border: "0.5px solid #A440F2",
              width: !isMobile ? "8rem" : "100%"
            }}
            disabled={geofence.appDisabled}
          >
            {t("mint-index", { index: "TCAP" })}
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
