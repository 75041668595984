import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { ToggleButton, ButtonGroup, Form, Button } from "react-bootstrap/esm"
import { PositionSide as V2PositionSide } from "@perennial/sdk"
import { ethers } from "ethers"
import { NumericFormat } from "react-number-format"
import { useTranslation } from "react-i18next"
import {
  useCollateral,
  useProductSnapshot,
  useActiveProvider,
  useUserProductSnapshot,
  useProtocolSnapshot,
  useTokensBalance,
} from "../hooks";
import { productContext, useGeofenceContext } from "../states"
import {
  BigMath,
  getLiquidationPrice,
  hasMaxDecimalsAllowed,
  isNonNegativeNumber,
  nextPosition,
} from "../utils/utils"
import { ApproveCollateralButton, OpenPositionButton } from "./ActionsButtons"
import { CollateralInput } from "./CollateralInput"
import { IndexInput } from "./IndexInput"
import { Fees } from "./Fees"
import { LeverageInput } from "../../components/Trade/TradeForm/components/LeverageInput"
import { ApproveType, MARKETS } from "../utils/constants"
import { CustomTooltip, LeverageSlider } from "../../components/common"
import { SelectMarkets } from "../../components/common/markets"


type props = {
  isTaker: boolean;
};

const Exchange = ({ isTaker }: props) => {
  const { t } = useTranslation()
  const marketActions = [
    { name: isTaker ? t("long") : t("provide-long"), value: "long" },
    { name: isTaker ? t("short") : t("provide-short"), value: "short" },
  ]
  const { chainId, userAccount: ownerAddress } = useActiveProvider();
  const currentProduct = useContext(productContext);
  const geofence = useGeofenceContext()
  const { data: tokenBalances } = useTokensBalance();
  const { data: protocol } = useProtocolSnapshot();
  const { product, mutateProductsSnapshots } = useProductSnapshot(currentProduct.productContract[chainId].address);
  const { data: collateralSnapshot, mutate: mutateCollateral } = useCollateral(currentProduct.productContract[chainId].address);
  const { userProduct, mutateUserProductSnapshot } =
    useUserProductSnapshot(ownerAddress, currentProduct.productContract[chainId].address);
  const [currentAction, setCurrentAction] = useState(marketActions[0].value);
  const [currentLeverage, setCurrentLeverage] = useState(1);
  const [inputLeverage, setInputLeverage] = useState("1");
  const [collateralAmount, setCollateralAmount] = useState("0");
  const [collateralAmountNumber, setCollateralAmountNumber] = useState(0);
  const [positionAmount, setPositionAmount] = useState("0");
  const [positionAmountUSD, setPositionAmountUSD] = useState("0");
  const [positionAmountRaw, setPositionAmountRaw] = useState(0n);
  const [currentLiqPrice, setCurrentLiqPrice] = useState("0");
  const [canOpenPosition, setCanOpenPosition] = useState(false);
  const [collateralError, setCollateralError] = useState("");
  const [positionError, setPositionError] = useState("");
  const tokenPrice = product ? BigInt(product.latestVersion.price) : 0n;
  const maintenance = product ? parseFloat(ethers.formatEther(product.maintenance)) : 1;

  const { productDelta, totalTaker, totalMaker } = useMemo(() => {
    let productDelta = "0";
    let totalTaker = 0n;
    let totalMaker = 0n;
    if (product) {
      const globalNextPosition = nextPosition(product.pre, product.position)
      totalTaker = globalNextPosition.taker;
      totalMaker = globalNextPosition.maker;

      const delta =  BigMath.abs(globalNextPosition.maker - globalNextPosition.taker);
      productDelta = parseFloat(ethers.formatEther(delta)).toFixed(4);
    }

    return {
      productDelta,
      totalTaker,
      totalMaker
    }
  }, [product])

  const maxLeverage = product ? 1 / maintenance : 20;
  const maxUserLeverage = maxLeverage > 20
    ? maxLeverage / 2
    : maxLeverage;

  useEffect(() => {
    const load = () => {
      if (userProduct) {
        setCanOpenPosition(
          userProduct.collateral === 0n && userProduct.positionSize === 0n
        );
        resetFields();
      }
    }
    load();
  }, [userProduct])

  const resetFields = () => {
    setCurrentLeverage(1);
    setInputLeverage("1");
    setCollateralAmount("0");
    setCollateralAmountNumber(0);
    setPositionAmount("0");
    setPositionAmountUSD("0");
    setPositionAmountRaw(0n);
    setCurrentLiqPrice("0");
    setCollateralError("");
    setPositionError("");
  };

  const checkLimitErrors = (positionSize: bigint) => {
    setPositionError("");

    if (totalTaker + positionSize > totalMaker && isTaker) {
      if (positionSize > 0n) {
        setPositionError(t("error.no-liquidity-2", { value: productDelta }))
      }
    }
  }

  const { collateralBalance } = useMemo(() => {
    let collateralBalance = 0;
    if (tokenBalances) {
      collateralBalance = parseFloat(ethers.formatUnits(tokenBalances.usdcBalance, "mwei"));
    }

    return { collateralBalance  }
  }, [tokenBalances])

  const { usdcAllowance } = useMemo(() => {
    let usdcAllowance = 0;
    if (collateralSnapshot) {
      usdcAllowance = parseFloat(ethers.formatUnits(collateralSnapshot.usdcAllowance, "mwei"));
    }

    return { usdcAllowance,  }
  }, [collateralSnapshot])

  const calculatePosition = useCallback(
    (newCollateralAmount: string, newLeverage: string) => {
      if (!isNonNegativeNumber(newCollateralAmount) || !isNonNegativeNumber(newLeverage)) return;
  
      if (tokenPrice === 0n) return;

      const parsedCollateralAmount = ethers.parseEther(newCollateralAmount);
      const parsedLeverage = ethers.parseEther(newLeverage);
      const newPosition = BigMath.abs(parsedLeverage * parsedCollateralAmount / tokenPrice);

      setPositionAmountRaw(newPosition);
      const newFormattedPosition = parseFloat(ethers.formatEther(newPosition)).toFixed(12);
      const notionalValue = parseFloat(newFormattedPosition) * parseFloat(ethers.formatEther(tokenPrice));

      setPositionAmount(newFormattedPosition);
      setPositionAmountUSD(Math.abs(notionalValue).toFixed(4));

      const liqPrice = getLiquidationPrice(
        product,
        newCollateralAmount,
        newFormattedPosition,
        currentAction === "long",
        currentProduct.market.displayDecimals,
      );
      setCurrentLiqPrice(liqPrice);
      checkLimitErrors(newPosition);
    },

    // eslint-disable-next-line
    [currentAction, product, tokenPrice]
  )
  
  const onLeverageChange = (value: number, index: number) => {
    setCurrentLeverage(value);
    setInputLeverage(value.toString());
    calculatePosition(collateralAmount, value.toString());
  };

  const onLeverageInputChange = (value: number, strValue: string) => {
    setInputLeverage(strValue);
    if (value !== -1) {
      setCurrentLeverage(value);
      calculatePosition(collateralAmount, value.toString());
    } else {
      checkLimitErrors(positionAmountRaw);
    }
  };

  const onCollateralChange = async (value: string) => {
    setCollateralError("");
    if (value !== "" && isNonNegativeNumber(value)) {
      if (hasMaxDecimalsAllowed(value, 6)) {
        setCollateralAmount(value);
        setCollateralAmountNumber(parseFloat(value));
        calculatePosition(value, currentLeverage.toString());
      }

      if (protocol) {
        const min = parseFloat(ethers.formatEther(protocol.minCollateral))
        if (parseFloat(value) < min) {
          setCollateralError(t("error.min-collateral", { value: "10 USDC" }))
        } else if (parseFloat(value) > collateralBalance) {
          setCollateralError(t("error.no-usdc"))
        }
      }      
    } else {
      setCollateralAmount("");
      setCollateralAmountNumber(0);
      calculatePosition("0", currentLeverage.toString())
    }
  };

  const onSetMaxAmount = () => { 
    onCollateralChange(collateralBalance.toString());
  }

  const onMarketTypeChange = (value: string) => {
    const nextProduct = MARKETS[currentProduct.market.key];
    if (value === "long") {
      currentProduct.setCurrentProductContract(nextProduct.longContract)
    } else {
      currentProduct.setCurrentProductContract(nextProduct.shortContract)
    }

    currentProduct.setCurrentType(value);
    setCurrentAction(value);
    resetFields();
    mutateCollateral();
    mutateUserProductSnapshot();
  }

  const isDataValid = (): boolean => {
    const valid =
      isNonNegativeNumber(collateralAmount) &&
      isNonNegativeNumber(positionAmount) &&
      collateralError === "" &&
      positionError === "" &&
      currentLeverage > 0 &&
      currentLeverage <= maxUserLeverage;
    
    return valid;
  };

  const onTransactionSuccess = () => {
    resetFields();
    mutateUserProductSnapshot();
    mutateCollateral();
    mutateProductsSnapshots();
  };

  return (
    <div className="exchange">
      <div className="exchange-inner">
        <div className="exchange-options2">
          <SelectMarkets isNewPosition={true} orderDirection={V2PositionSide.long} showStats={true} />
          <div className="options">
            <ButtonGroup>
              {marketActions.map((action, idx) => (
                <ToggleButton
                  key={idx}
                  id={`market-${idx}`}
                  type="radio"
                  name="radio"
                  value={action.value}
                  checked={currentAction === action.value}
                  onChange={(e) => onMarketTypeChange(e.currentTarget.value)}
                >
                  {action.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
          <div className="controls">
            <CollateralInput
              control_id="exchange-collateral-input-id"
              showBalance={true}
              value={collateralAmount}
              title={t("collateral")}
              onChange={onCollateralChange}
              onSetMaxAmount={onSetMaxAmount}
              maxCaption={t("balance")}
            />
            {collateralError !== "" && (
              <span className="error">
                {collateralError}
              </span>
            )}
            <IndexInput
              tokenSymbol={currentProduct.market.key}
              showTokenBalance={false}
              changingAmount={false}
              amount={positionAmount}
              amountUsd={positionAmountUSD}
            />
            {positionError !== "" && (
              <span className="error">
                {positionError}
              </span>
            )}
            <Form.Group className="leverage-group">
              <div className="leverage-header">
                <CustomTooltip
                  id="exlev-price"
                  msg={t("tooltip.leverage")}
                  showIcon={true}
                  iconSize={13}
                >
                  <h6 className="margin-right">{t("leverage")}</h6>
                </CustomTooltip> 
                <LeverageInput
                  controlId="exchange-lev-input"
                  value={inputLeverage}
                  max={maxUserLeverage}
                  onChange={onLeverageInputChange}
                />
              </div>  
              <LeverageSlider
                defaultValue={1}
                value={currentLeverage}
                maxValue={maxUserLeverage}
                onChange={onLeverageChange}
              />
              <div className="leverage-values">
                <span className="small">1x</span>
                <span className="small">{maxUserLeverage}x</span>
              </div>
            </Form.Group>
          </div>
          <div className="liquidation">
            <CustomTooltip
              id="exliq-price"
              msg={t("tooltip.liquidation-price")}
              showIcon={true}
              iconOnLeft={true}
              iconSize={13}
            >
              <h6 className="bold margin-left">{t("liquidation-price")}:</h6>
            </CustomTooltip>
            <CustomTooltip
              id="exliq-price"
              msg={t("tooltip.liquidation-price")}
            >
              <NumericFormat
                className="balance-usd bold"
                value={currentLiqPrice}
                displayType="text"
                thousandSeparator=","
                suffix=" USD"
                decimalScale={currentProduct.market.displayDecimals}
              />
            </CustomTooltip> 
          </div>
          <Fees
            productAddress={currentProduct.productContract[chainId].address}
            positionDelta={positionAmountRaw}
            isTaker={isTaker}
            isLiquidity={false}
          />
          {!geofence.appDisabled ? (
            <>
              {collateralAmount === "" || parseFloat(collateralAmount) <= usdcAllowance  ? (
                <OpenPositionButton
                  isTaker={isTaker}
                  productAddress={currentProduct.productContract[chainId].address}
                  collateralAmount={collateralAmountNumber}
                  collateralBalance={collateralBalance}
                  position={positionAmountRaw}
                  exposure={parseFloat(positionAmountUSD)}
                  writeEnable={canOpenPosition && isDataValid()}
                  onSuccess={onTransactionSuccess}
                />
              ) : (
                <ApproveCollateralButton
                  approvalAction={ApproveType.DEPOSIT}
                  currentAllowance={usdcAllowance}
                  amountToApprove={collateralAmountNumber}
                  onSuccess={() => {
                    mutateCollateral();  
                  }}
                />
              )}
            </>
          ) : (
            <>
              <Button className="btn btn-primary btn-lg btn-block " disabled={true}>{t("app-not-available")}</Button>
              <div className="p-2">
                <small className="text-muted">
                  {t("info-msg.geoblock")}
                  <a href="/terms-of-service" target="_blank">{t("info-msg.terms-and-conditions")}</a>.
                </small>
              </div>
            </>
          )}
        </div>
      </div>  
    </div>    
  )    
}

export default Exchange
