import React from "react"
import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Hour } from "@perennial/sdk"
import { base } from "viem/chains"
import { WagmiProvider } from "wagmi"
import ReactGA from "react-ga4"

import { CookiesProvider } from "react-cookie"
import "./styles/app.scss"
import "react-toastify/dist/ReactToastify.css"
import "./styles/toast.scss"
import { CryptexProductsProvider } from "./contexts"
import { GeofenceProvider } from "./contexts"

import { wagmiConfig } from "./constants/network"
import Main from "./pages/Main"


const tanstackQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Number(1n * Hour) * 1000, // 1 hour in ms
    },
  },
})

const App = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRAKING_ID || "");

  return (
    <QueryClientProvider client={tanstackQueryClient}>
      <GeofenceProvider>
        <WagmiProvider config={wagmiConfig}>
          <RainbowKitProvider
            appInfo={{
              appName: "Cryptex Finance",
              learnMoreUrl: "https://docs.cryptex.finance/"
            }}
            initialChain={base.id}
            theme={darkTheme({
              accentColor: "#A440F2",
              accentColorForeground: "white",
              borderRadius: "small",
              fontStack: "system",
              overlayBlur: "small",
            })}
            modalSize={"compact"}
            showRecentTransactions
          >
            <CryptexProductsProvider>
              <CookiesProvider>
                <Main />
              </CookiesProvider> 
            </CryptexProductsProvider>
          </RainbowKitProvider>
        </WagmiProvider>
      </GeofenceProvider>  
    </QueryClientProvider>
  );
}

export default App;
