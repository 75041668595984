const numbersOnlyRegex = /^\d*\.?\d*$/


export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const isNumbersOnly = (value: string) => numbersOnlyRegex.test(value)

export const isPositiveNumber = (value: any): boolean => {
  if (isNumbersOnly(value)) {
    return parseFloat(value) > 0;
  }
  return false;
};

export const isNonNegativeNumber = (value: any): boolean => {
  if (isNumbersOnly(value)) {
    return parseFloat(value) >= 0;
  }
  return false;
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const formatUSDPrice = (
  value: number = 0,
  {
    compact = false,
    fromUsdc = false,
    fullPrecision = false,
  }: { compact?: boolean; fromUsdc?: boolean; fullPrecision?: boolean } = {},
) => {
  if (value === 0) {
    return '$0.00'
  }

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: compact ? 'compact' : undefined,
    minimumFractionDigits: 2,
    maximumFractionDigits: fullPrecision ? 8 : 2,
    minimumSignificantDigits: compact ? 2 : 8,
    maximumSignificantDigits: compact ? 2 : 8,
    // @ts-ignore
    roundingPriority: 'morePrecision',
  }).format(value)
}
