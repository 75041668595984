import React, { useMemo, useState } from "react"
import { Route, Routes } from "react-router-dom"
import { Container } from "react-bootstrap/esm"
import { ToastContainer } from "react-toastify"

import Header from "../components/Header";
import { useAddress } from "../hooks/network"
import TermsOfServiceModal from "../components/TermsOfService"
import GovernancePage from "./governance"
import IndexVaultPage from "./indexes"

import TradePage from "./trade"
import { BetaWhitelist } from "../constants/network"
import { WhitelistPage } from "../components/WhitelistPage"
import { LoadingPage } from "../components/common/"
import SpotPage from "./spot"
import IncentivesPage from "./incentives"
import { useGeofenceContext } from "../contexts"
import TermsPage from "./terms"
import OneToOnePage from "./crypdex";
import { GeofenceBanner } from "../components/common/Banners";


const Main = () => {
  const geofence = useGeofenceContext();
  const { address } = useAddress()
  const hasTOS = (localStorage.getItem("tosAccepted2") === "true");
  const [showTOSModal, setShowTOSModal] = useState<boolean | undefined>(!hasTOS);

  const isAccountAllowed = useMemo(() => {
    const whitelist = process.env.REACT_APP_WHITELIST ?? "false"
    if (whitelist === "false") return true
    if (!address) return false;

    return BetaWhitelist.map((address) => address.toLowerCase()).includes(address.toLowerCase())
  }, [address])

  return (
    <Container fluid className="wrapper transparent-background">
      <Header />
      <GeofenceBanner />
      {geofence.geoLocationData !== undefined ? (
        <>
          {isAccountAllowed ? (
            <>
              <ToastContainer />
              <Routes>
                <Route path="/" element={<IndexVaultPage />} />
                <Route path="/emissions/" element={<IncentivesPage />} />
                <Route path="/spot" element={<SpotPage />} />
                <Route path="/perpetuals" element={<TradePage isProInterface={false} />} />
                <Route path="/perpetuals/liquidity/" element={<TradePage isProInterface={true} />} />
                <Route path="/governance/" element={<GovernancePage />} />
                <Route path="/arfi/" element={<OneToOnePage />} />
                <Route path="/terms-of-service" element={<TermsPage />} />
              </Routes>
              <TermsOfServiceModal showModal={showTOSModal} onHide={() => setShowTOSModal(false) } />
            </>
          ) : (
            <WhitelistPage />  
          )}
        </>
      ) : (
        <LoadingPage />
      )}
    </Container>
  )
}

export default Main;
