import React, { useMemo, useState } from "react"
import { Tabs, Tab } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"
import { CurrentPositions } from "./CurrentPositions"
import { UserActiviyWrapper } from "./UserActiviy"
import { useActiveProvider } from "../../hooks"
import { zeroAddress } from "viem"


type props = {
  userSnapshots: any;
  isLoadingPositions: boolean;
  positionType: string;
}

export const Positions = ({ userSnapshots, isLoadingPositions, positionType }: props) => {
  const { t } = useTranslation()
  const { userAccount, isConnected } = useActiveProvider();
  const [activitiesClicked, setActivitiesClicked] = useState(false);

  const { openProducts } = useMemo(() => {
    let openProducts = new Array<string>();
    
    if (userSnapshots) { 
      const addresses = Object.keys(userSnapshots);
      
      for (let i = 0; i < addresses.length; i += 1) { 
        const productAddress = addresses[i];
        const userSnapshot = userSnapshots[productAddress];

        if ((positionType === userSnapshot.positionDirection || userSnapshot.positionDirection === "none")  &&
          (userSnapshot.collateral > 0n || userSnapshot.positionSize > 0n)) {
          openProducts.push(addresses[i].toLowerCase());
        }
      }
    }

    return {
      openProducts
    }
  }, [userSnapshots, positionType]);

  return (
    <div className="positions">
      <Tabs
        defaultActiveKey="my-positions"
        id="uncontrolled-tab-example"
        className="mb-3"
        onSelect={(k) => {
          if (k === "history") {
            setActivitiesClicked(true);
          }
        }}
      >
        <Tab eventKey="my-positions" title={t("my-positions")}>
          {isConnected && userAccount !== zeroAddress && (
            <CurrentPositions
              ownerAddress={userAccount}
              positionType={positionType}
              userSnapshots={userSnapshots}
              openProducts={openProducts}
              isLoadingPositions={isLoadingPositions}
            />
          )}
        </Tab>
        <Tab eventKey="history" title={t("activity")}>
          {isConnected && userAccount !== zeroAddress && activitiesClicked && (
            <UserActiviyWrapper positionType={positionType} />
          )}  
        </Tab>
      </Tabs>
    </div>    
  )
};
