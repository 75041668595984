import React, { useMemo, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { formatBig18, Big18Math } from '@perennial/sdk';
import { useTranslation } from "react-i18next";
import { Address } from "viem";

import "../../../styles/modals.scss";
import { NumericFormInput } from "../../common/NumericFormInput";
import { useStakerTransactions } from "../../../hooks/governance/tx";
import { isNumbersOnly } from "../../../utils/formUtils";
import { isNonNegativeNumber } from "../../../utils/utils";
import { useStakerWaitTime } from "../../../hooks/governance";
import { useGeofenceContext } from "../../../contexts";


type props = {
  show: boolean;
  keeperAddress: Address;
  stakedAmount: bigint;
  onHide: () => void;
};

const Withdraw = ({
  show,
  keeperAddress,
  stakedAmount,
  onHide,
}: props) => {
  const { t } = useTranslation();
  const geofence = useGeofenceContext();
  const { onWithdraw } = useStakerTransactions();
  const { data: currentWaitTime } = useStakerWaitTime(keeperAddress)
  const [withdrawAmount, setWithdrawAmount] = useState(formatBig18(stakedAmount, { useGrouping: false }));
  const [withdrawAmountBI, setWithdrawAmountBI] = useState(stakedAmount);
  const [writingTran, setWritingTran] = useState(false);

  const { canWithdraw, withdrawDate } = useMemo(() => { 
    if (!currentWaitTime) return { withdrawDate: "", canWithdraw: true };

    return {
      withdrawDate: (new Date(currentWaitTime * 1000)).toLocaleString(),
      canWithdraw: currentWaitTime < new Date().getTime() / 1000
    }
  }, [currentWaitTime])

  const { withdrawError } = useMemo(() => {
    let withdrawError = "";

    if (isNumbersOnly(withdrawAmount)) {
      if (withdrawAmountBI > stakedAmount) {
        withdrawError = t("governance.errors.invalid-amount");
      }
    } else {
      withdrawError = t("error.not-a-number");
    }

    return {
      withdrawError,
    }
  },
    // eslint-disable-next-line
    [stakedAmount, withdrawAmount]
  )

  const withdraw = async (event: React.MouseEvent) => {
    event.preventDefault();
    setWritingTran(true);
    if (withdrawError === "") { 
      await onWithdraw(keeperAddress, withdrawAmountBI);
      onHide();
    }
    setWritingTran(false);
  };

  return (
    <Modal
      show={show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setWithdrawAmount("");
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <h4>{t("governance.withdraw")}</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <p style={{ marginBottom: "1.5rem", color: "#aaa9bf" }}>
            {t("governance.withdraw-info")}
          </p>
          <NumericFormInput
            controlId="ctx-unstake"
            showBalance={true}
            amount={withdrawAmount}
            title={t("governance.amount-remove")}
            titleSize={"1.1rem"}
            maxAmount={stakedAmount}
            tokenPrice={0n}
            maxCaption="Staked"
            onChange={(value: string) => {
              if (isNonNegativeNumber(value) || value.trim() === "") { 
                setWithdrawAmount(value);
                if (value.trim() === "") {
                  setWithdrawAmountBI(0n);
                } else {
                  setWithdrawAmountBI(Big18Math.fromFloatString(value));  
                }
              }
            }}
            onSetMaxAmount={() => {
              setWithdrawAmount(formatBig18(stakedAmount, { useGrouping: false, minDecimals: 10 }));
              setWithdrawAmountBI(stakedAmount);
            }}
            is18Decimals={true}
            tokenSymbol="CTX"
            showUSDValue={false}
          />
          {!canWithdraw && (
            <span className="text-red">
              {t("governance.withdraw-info2", { date: withdrawDate })}
            </span>
          )}
          {withdrawError !== "" && canWithdraw && (
            <span className="text-red">{withdrawError}</span>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="mb-4 w-100"
          onClick={withdraw}
          disabled={writingTran || withdrawAmountBI === 0n || withdrawError !== "" || !canWithdraw || geofence.appDisabled}
        >
          <div className="btn-spinner">
            {writingTran && <Spinner animation="border" variant="secondary" className="small" />}
            {!writingTran ? t("governance.withdraw") : t("governance.withdrawing")}
          </div>  
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Withdraw;
