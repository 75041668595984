import { useQuery } from "@tanstack/react-query"
import { Address, parseUnits, zeroAddress, formatUnits } from "viem"
import { arbitrum } from "viem/chains"
import { ParaSwapVersion, SwapSide } from "@paraswap/core"
import axios, { AxiosResponse } from "axios"

import { useAddress, useChainId } from "../network"
import { ChainTokens, SupportedTokens, TokenMetadata } from "../../constants/tokens"
import { ComponentMetadata, SetTokenMetadata, SupportedComponents, SupportedSetTokens } from "../../constants/crypdex"
import { LocalStorageVars, ParaswapApiUrl, ParaswapSlippage, ParaswapSwapEndpoint, PriceQueryParams } from "../../constants/paraswap"
import { SupportedChainIdType } from "../../constants/network"
import { fetchComponentUnitsForIssue } from "."


export type BuyComponentsDataType = Record<SupportedComponents, {
  payload: `0x${string}`,
  srcAmount: number,
  srcAmountBI: bigint,
  error?: string
}>;

export type SellComponentsDataType = Record<SupportedComponents, {
  payload: `0x${string}`,
  units: bigint,
  destAmount: number,
  destAmountBI: bigint,
  error?: string
}>;


export const useBuyComponentsData = (setToken: SupportedSetTokens, setTokenAmount: bigint, srcToken: SupportedTokens, enabled: boolean) => { 
  const chainId = useChainId()
  const userAddress = useAddress()
    
  return useQuery({
    queryKey: ['BuyComponentsData', chainId, setToken, srcToken, setTokenAmount.toString(), userAddress.address],
    enabled: enabled && setTokenAmount > 0n && !!userAddress.address,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      return await fetchBuyComponentsData(chainId, setToken, setTokenAmount, srcToken, userAddress.address || zeroAddress)
    },
  })

}

export const  fetchBuyComponentsData = async (
  chainId: SupportedChainIdType,
  setToken: SupportedSetTokens,
  setTokenAmount: bigint,
  srcToken: SupportedTokens,
  userAddress: Address,
) => {
  const currentSlippage = localStorage.getItem(LocalStorageVars.arfiSlippage) || ParaswapSlippage;
  const currentMaxPriceImpact = localStorage.getItem(LocalStorageVars.arfiMaxPriceImpact) || "15";
  const buyComponentsData = {} as BuyComponentsDataType;
  const setTokenMetadata = SetTokenMetadata[setToken];
  const componentsUnits = await fetchComponentUnitsForIssue(chainId, setToken, setTokenAmount);

  const scrMetadata = TokenMetadata[srcToken];
  const srcTokenAddress = ChainTokens[chainId][srcToken];

  try {
    const swapCalls = new Array<Promise<AxiosResponse<any>>>();
    Object.keys(componentsUnits).forEach((component) => {
      if (component !== SupportedComponents.floki) {
        const componentMetadata = ComponentMetadata[component as SupportedComponents];
        const cUnit = componentsUnits[component as SupportedComponents];

        const queryParams: PriceQueryParams = {
          srcToken: srcTokenAddress || zeroAddress,
          destToken: cUnit.address,
          srcDecimals: scrMetadata.decimals.toString(),
          destDecimals: componentMetadata.decimals.toString(),
          amount: cUnit.unit.toString(),
          side: SwapSide.BUY,
          network: arbitrum.id.toString(),
          version: ParaSwapVersion.V6,
          userAddress,
          slippage: currentSlippage,
          maxImpact: currentMaxPriceImpact,
          excludeDEXS: "ParaSwapPool,ParaSwapLimitOrders",
        };

        const searchString = new URLSearchParams(queryParams);
        const swapsURL = `${ParaswapApiUrl}${ParaswapSwapEndpoint}/?${searchString}`;

        // console.log("swapsURL", swapsURL)
        swapCalls.push(axios.get(swapsURL));
      }
    })

    for (let i = 0; i < swapCalls.length; i += 1) {
      try {
        const route = await swapCalls[i];
        const srcAmount = parseFloat(formatUnits(BigInt(route.data.priceRoute.srcAmount), scrMetadata.decimals)) * 1.02;
        buyComponentsData[setTokenMetadata.components[i]] = {
          payload: route.data.txParams.data,
          srcAmount: srcAmount,
          srcAmountBI: parseUnits(srcAmount.toString(), scrMetadata.decimals)
        }
      } catch (e: any) {
        buyComponentsData[setTokenMetadata.components[i]] = {
          payload: zeroAddress,
          srcAmount: 0,
          srcAmountBI: 0n,
          error: e.response.data.error
        }
      }
    }
  }
  catch (e) { 
    console.error("ERROR buy components", e)
  }
  return buyComponentsData;
}


export const useSellComponentsData = (setToken: SupportedSetTokens, setTokenAmount: bigint, destToken: SupportedTokens, enabled: boolean) => { 
  const chainId = useChainId()
  const userAddress = useAddress()
    
  return useQuery({
    queryKey: ['SellComponentsData', chainId, setToken, destToken, setTokenAmount.toString(), userAddress.address],
    enabled: enabled && setTokenAmount > 0n && !!userAddress.address,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      return await fetchSellComponentData(chainId, setToken, setTokenAmount, destToken, userAddress.address || zeroAddress)
    },
  })

}

export const fetchSellComponentData = async (
  chainId: SupportedChainIdType,
  setToken: SupportedSetTokens,
  setTokenAmount: bigint,
  destToken: SupportedTokens,
  userAddress: Address,
) => {
  const currentSlippage = localStorage.getItem(LocalStorageVars.arfiSlippage) || ParaswapSlippage;
  const currentMaxPriceImpact = localStorage.getItem(LocalStorageVars.arfiMaxPriceImpact) || "15";
  const sellComponentsData = {} as SellComponentsDataType;
  const setTokenMetadata = SetTokenMetadata[setToken];
  const componentsUnits = await fetchComponentUnitsForIssue(chainId, setToken, setTokenAmount);

  const destMetadata = TokenMetadata[destToken];
  const destTokenAddress = ChainTokens[chainId][destToken];
  
  try {
    const swapCalls = new Array<Promise<AxiosResponse<any>>>();  
    Object.keys(componentsUnits).forEach((component) => {
      if (component !== SupportedComponents.floki) {
        const componentMetadata = ComponentMetadata[component as SupportedComponents];
        const cUnit = componentsUnits[component as SupportedComponents];

        const queryParams: PriceQueryParams = {
          srcToken: cUnit.address,
          destToken: destTokenAddress || zeroAddress,
          srcDecimals: componentMetadata.decimals.toString(),
          destDecimals: destMetadata.decimals.toString(),
          amount: cUnit.unit.toString(),
          side: SwapSide.SELL,
          network: arbitrum.id.toString(),
          version: ParaSwapVersion.V6,
          userAddress,
          slippage: currentSlippage,
          maxImpact: currentMaxPriceImpact,
          excludeDEXS: "ParaSwapPool,ParaSwapLimitOrders",
        };
        const searchString = new URLSearchParams(queryParams);
        const swapsURL = `${ParaswapApiUrl}${ParaswapSwapEndpoint}/?${searchString}`;
        swapCalls.push(axios.get(swapsURL));
      }
    })

    const swapRoutes = await Promise.all(swapCalls);
    for (let i = 0; i < swapCalls.length; i += 1) { 
      try {
        const route = swapRoutes[i];
        sellComponentsData[setTokenMetadata.components[i]] = {
          payload: route.data.txParams.data,
          units: componentsUnits[setTokenMetadata.components[i]].unit,
          destAmount: parseFloat(formatUnits(route.data.priceRoute.destAmount, destMetadata.decimals)),
          destAmountBI: BigInt(route.data.priceRoute.destAmount),
        }
      } catch (e: any) {
        sellComponentsData[setTokenMetadata.components[i]] = {
          payload: zeroAddress,
          units: 0n,
          destAmount: 0,
          destAmountBI: 0n,
          error: e.response.data.error
        }
      }
    }

  } catch (e) { 
    console.error("ERROR sell components data", e)
  }

  return sellComponentsData;
}