import React, { useEffect, useMemo, useState }  from "react";
import { Button, Form, Image, InputGroup, Stack } from "react-bootstrap/esm";
import { formatBig18 } from "@perennial/sdk";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useMediaQuery } from "react-responsive";
import { FaGear } from "react-icons/fa6";

import { useCrypdexContext } from "../../../contexts/crypdexContext";
import { SetTokenMetadata } from "../../../constants/crypdex";
import { formatUSDPrice, isNumbersOnly } from "../../../utils/utils";
import { LocalStorageVars, ParaswapSlippage } from "../../../constants/paraswap";
import { CustomTooltip } from "../../common";


type props = {
  controlId: string;
  value: string;
  title: string;
  priceOneSetToken: number;
  onFocus: () => void;
  onBlur: () => void;
  onChange: (event: string) => void;
  onSetMaxAmount: (amount: bigint) => void;
  isIssuing?: boolean;
  inputClassName?: string;
  disabled?: boolean;
}

export const IndexInput = ({
  controlId,
  value,
  title,
  priceOneSetToken,
  onFocus,
  onBlur,
  onChange,
  onSetMaxAmount,
  isIssuing,
  inputClassName = "",
  disabled = false,
}: props) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const { selectedSetToken, userCurrentSetToken } = useCrypdexContext();
  // const { data: tokenPriceData } = useSetTokenPrice(selectedSetToken);
  const currentSetTokenMetadata = SetTokenMetadata[selectedSetToken];

  const { setTokenPrice } = useMemo(() => {
    return {
      setTokenPrice: priceOneSetToken
    };
  }, [priceOneSetToken]);

  const { maxValue, maxValueBI, maxValueUSD } = useMemo(() => {
    if (!userCurrentSetToken) return { maxValue: "0", maxValueBI: 0n, maxValueUSD: "0" };

    return {
      maxValue: formatBig18(userCurrentSetToken.balance),
      maxValueBI: userCurrentSetToken.balance,
      maxValueUSD: formatUSDPrice(parseFloat(formatBig18(userCurrentSetToken.balance)) * setTokenPrice)
    };
  },
    // eslint-disable-next-line
    [userCurrentSetToken, setTokenPrice]
  );

  const { valueUsd } = useMemo(() => {
    let valueUsd = "0";
    if(isNumbersOnly(value)) {
      valueUsd = (parseFloat(value) * setTokenPrice).toFixed(2);
    }
    return {
      valueUsd
    };
  },
    [value, setTokenPrice]
  );

  return (
    <Form.Group className="asset-input-group" style={{ marginTop: "0.7rem", marginBottom: "0.1rem" }}>
      <Stack direction="horizontal" className="position-relative justify-content-between asset-input-top mb-1">
        <h6>{title}</h6>
        <ConfigButton />
      </Stack>
      <InputGroup className="input-collateral">
        <Stack direction="horizontal" className="w-100">
          <Stack direction="vertical" className="justify-content-between" style={{ width: "58%" }}>
            <Form.Control
              id={controlId}
              placeholder="0"
              className={`balance-input ${inputClassName}`}
              disabled={disabled}
              value={value}
              type={isMobile ? "number" : "text"}
              onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)
              }
              onFocus={() => onFocus()}
              onBlur={() => onBlur()}
              style={{ position: "relative", zIndex: 1 }}
            />
            <NumericFormat
              className="balance-usd text-muted"
              value={valueUsd}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              suffix=" USD"
            />
          </Stack> 
          <div className="asset-info" style={{ width: "42%" }}>
            <div className="asset-info2">
              <Image src={currentSetTokenMetadata.icon} height={32} width={32} />
            </div>
            <div className="asset-balance w-100">
              <span className="title">
                {!isMobile ? "Wallet Balance" : t("balance")}
              </span>
              <CustomTooltip
                id="index-usd-balance"
                msg={maxValueUSD}
                showIcon={false}
                placement="auto"
              >
                <span className="balance me-0 text-decoration-dotted text-decoration-muted">{maxValue}</span>
              </CustomTooltip>  
              {!isIssuing && (
                <Button
                  variant="secondary"
                  className="small ms-1"
                  onClick={() => onSetMaxAmount(maxValueBI || 0n)}
                >
                  {t("max")}
                </Button>
              )}
            </div>
          </div>
        </Stack>
      </InputGroup>
    </Form.Group>
  );    
}


const ConfigButton = () => { 
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [showConfig, setShowConfig] = useState(false);
  const [slippage, setSlippage] = useState("1");
  const [maxPriceImpact, setMaxPriceImpact] = useState("15");

  useEffect(() => { 
    const currentSlippage = localStorage.getItem(LocalStorageVars.arfiSlippage) || ParaswapSlippage;
    const currentMaxPriceImpact = localStorage.getItem(LocalStorageVars.arfiMaxPriceImpact) || "15";

    if (isNumbersOnly(currentSlippage)) {
      setSlippage((parseFloat(currentSlippage) / 100).toFixed(0));
    }
    if (isNumbersOnly(currentMaxPriceImpact)) {
      setMaxPriceImpact(currentMaxPriceImpact);
    } 

  },[])

  const handleSlippageChange = (value: string) => { 
    if (isNumbersOnly(value)) {
      setSlippage(value);
      if (parseFloat(value) <= 30) {
        localStorage.setItem(LocalStorageVars.arfiSlippage, (parseFloat(value) * 100).toFixed(0));
      }
    }
  }

  const handleMaxPriceImpactChange = (value: string) => { 
    if (isNumbersOnly(value)) {
      setMaxPriceImpact(value);
      if (parseFloat(value) <= 50) {
        localStorage.setItem(LocalStorageVars.arfiMaxPriceImpact, value);
      }
    }
  }

  return (
    <>
      <Button
        variant="outline-primary"
        className="config-button p-0 me-2"
        onClick={() => setShowConfig(!showConfig)}
      >
        <FaGear size={18} />
      </Button>
      {showConfig && (
        <Stack
          direction="vertical"
          className="config-dropdown"
          gap={2}
          style={{
            position: "absolute",
            top: "1.7rem",
            right: "7px",
            backgroundColor: "#0a0a0a",
            border: "0.5px solid #333",
            borderRadius: "2px",
            padding: "0.75rem",
            zIndex: 1000,
          }}
        >
          <Stack direction="horizontal" gap={2}>
            <span className="text-muted small w-55">Slippage (%):</span>
            <Form.Control
              id="slippage"
              placeholder="0"
              className="small text-right w-45 pe-1"
              value={slippage}
              type={isMobile ? "number" : "text"}
              onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => handleSlippageChange(event.target.value)
              }
              style={{
                position: "relative",
                zIndex: 1,
                border: "1px solid #333",
                borderRadius: "2px",
              }}
            />
          </Stack>
          <Stack direction="horizontal" gap={2}>
            <span className="text-muted small w-55">Max Price Impact (%):</span>
            <Form.Control
              id="slippage"
              placeholder="0"
              className="small text-right w-45 pe-1"
              value={maxPriceImpact}
              type={isMobile ? "number" : "text"}
              onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => handleMaxPriceImpactChange(event.target.value)
              }
              style={{
                position: "relative",
                width: "4rem",
                zIndex: 1,
                border: "1px solid #333",
                borderRadius: "2px",
              }}
            />
          </Stack>
        </Stack>
      )}
    </>  
  )
}