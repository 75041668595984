import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap/esm";
import { Address, zeroAddress } from "viem";
import { PerennialVaultType } from "@perennial/sdk";
import "../../../styles/modals.scss";

import { useOperatorTransactions } from "../../../hooks/wallet"
import { useVaultTransactions } from "../../../hooks/vaults2"
import { notifyUser } from "../notifications";
import { useOnPerpetualsSupportedChain } from "../../../hooks/network";
import { useTranslation } from "react-i18next";


type props = {
  isMarket: boolean;
  vaultAddress?: Address
  onHide: () => void;  
};

export const ApproveOperatorButton = ({ isMarket, onHide, vaultAddress }: props) => {
  const { t } = useTranslation()
  const { isUsingSupportedChain } = useOnPerpetualsSupportedChain()
  const [approving, setApproving] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { onApproveMultiInvokerOperator } = useOperatorTransactions()
  const { onApproveOperator } = useVaultTransactions({
    vaultType: PerennialVaultType.bravo,
    vaultAddress: vaultAddress || zeroAddress 
  })
  
  return (
    <>
      <Button
        className="deposit-button bold"
        onClick={() => setShowModal(true)}
        disabled={approving || !isUsingSupportedChain || true}
      >
        {isMarket ? t("open-position") : t("deposit")}
      </Button>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
          onHide()
        }}
        className="modal-approve-operator"
      >
        <Modal.Header closeButton>
          <h5 className="bold">{t("approve-operator")}</h5>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("info-msg.perennial-operator")}
          </p>        
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bold"
            onClick={async () => {
              setApproving(true)
              if (isMarket) {
                await onApproveMultiInvokerOperator()
              } else {
                await onApproveOperator()
              }  
              setShowModal(false)
              notifyUser(t("notification.operator-approved"))
              onHide()
            }}
            disabled={approving || true}
          >
            <div className="btn-spinner">
              {approving && <Spinner animation="border" variant="secondary" className="small" />}
              {t("approve-operator")}
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </>  
  );   
};
