import React from "react"
import ReactGA from "react-ga4"
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client"

import TradeWrapper from "../../components/Trade/"
import { PerennialSDKProvider } from "../../contexts/perennialSdkContext";
import { positionStatsContext, productContext } from "../../v1/states";
import { usePositionStatsContext, useProductContext } from "../../v1/hooks";
import { ARBITRUM_GRAPHQL_URL } from "../../v1/utils/constants";


const TradePage = ({ isProInterface } : { isProInterface: boolean }) => {
  ReactGA.send({ hitType: "pageview", page: "/perpetuals", title: "Perpetuals Trade" });
  const apolloClient = new ApolloClient({
    uri: ARBITRUM_GRAPHQL_URL,
    cache: new InMemoryCache(),
  });
  const positionStats = usePositionStatsContext();
  const product = useProductContext();

  return (
    <ApolloProvider client={apolloClient}>
      <PerennialSDKProvider>
        <productContext.Provider value={product}>
          <positionStatsContext.Provider value={positionStats}>
            <TradeWrapper isProInterface={isProInterface} />
          </positionStatsContext.Provider>
        </productContext.Provider>  
      </PerennialSDKProvider>
    </ApolloProvider>
  )
};

export default TradePage;
