import React from "react"
import { useMediaQuery } from "react-responsive"

import Exchange from "../components/Exchange";
import { PriceChartWrapper, TradingViewWidget } from "../components/charts"
import { Positions } from "../components/positions"
import { PriceBox } from "../components/common"

import {
  useActiveProvider,
  useUserProductSnapshots,
} from "../hooks";
import { OpenPositionType } from "../utils/types";
import MobileMarkets from "./mobile/MobileMarkets";
import TabsChart from "../../components/TabsChart";


const Markets = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [tabState, setTabState] = React.useState("market");
  const { userAccount } = useActiveProvider();
  const { data: userSnapshots, isLoading } = useUserProductSnapshots(userAccount);
 
  return(
    <>
      {!isMobile ? (
        <>
          <TabsChart onTabChange={setTabState} />
          <div className="trade-module">
            <PriceBox showMarketCap={true} />
            <div className="trade-top">
              <div className="trade-left">
                {tabState === "market" ?  <TradingViewWidget/> : <PriceChartWrapper />}
              </div>
              <div className="trade-right markets-v1">
                <Exchange isTaker={true} />
              </div>
            </div>
          </div>
          <div className="trade-bottom">
            <Positions
              userSnapshots={userSnapshots}
              isLoadingPositions={isLoading}
              positionType={OpenPositionType.Taker}
            />
          </div>
        </>
      ) : (
        <MobileMarkets />
      )} 
    </>
  );
};

export default Markets;
