import React from "react";
import { Spinner, Stack } from "react-bootstrap";
import { PositionSide, SupportedAsset } from '@perennial/sdk';
import { useMediaQuery } from "react-responsive";
import ReactGA from "react-ga4"

import { MarketProvider, useMarketContext } from '../../contexts/marketContext'
import { useRefreshKeysOnPriceUpdates2 } from '../../hooks/markets2'

import TradeForm from "./TradeForm"
import Positions from "./Positions"
import { TradingViewWidget } from "./charts/TradingViewWidget"
import { MobileTradingViewWidget } from "./charts/MobileTradingViewWidget"
import { MarketInfoMetrics } from "../common/MarketMetrics"
import { LivePricesProvider } from "../../contexts/livePriceContext";
import { TradingViewChart } from "../Charts";
import { MarketMetadata } from "../../constants/markets";
import { useCryptexProductsContext } from "../../contexts";
import Markets from "../../v1/pages/Markets"


const Trade = ({ isProInterface }: { isProInterface: boolean }) => {
  useRefreshKeysOnPriceUpdates2()

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const {
    selectedMarket,
    orderDirection,
    setOrderDirection,
    selectedMakerMarket,
    isMaker,
    snapshots2,
    userCurrentPosition,
  } = useMarketContext()
  const market = snapshots2?.market?.[isMaker ? selectedMakerMarket : selectedMarket]
  const marketMetadata = MarketMetadata[market?.market || SupportedAsset.eth]

  return(
    <>
      <div className={(!isMobile ? "trade-module" : "mobile-trade").concat(" trade-v2")}>
        <div className="trade-top">
          <ChartWrapper market={marketMetadata.baseCurrency} />
          <div className="trade-right">
            {market ? (
              <Stack direction="vertical" gap={2}>
                <TradeForm
                  isNewPosition={true}
                  isProInterface={isProInterface}
                  asset={isMaker ? (selectedMakerMarket as SupportedAsset) : selectedMarket}
                  orderSide={isMaker ? PositionSide.maker : orderDirection}
                  setOrderDirection={setOrderDirection}
                  market={market}
                  position={userCurrentPosition}
                  showPositionAmount={true}
                />
              </Stack>  
            ) : (
              <div className="spinner-container trade">
                <Spinner animation="border" variant="primary" className="small" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="trade-bottom">
        <Positions isProInterface={isProInterface} />
      </div>
    </>  
  );
};

const ChartWrapper = ({ market }: { market: SupportedAsset }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const marketMetadata = MarketMetadata[market]

  return (
    <>
      {isMobile && !marketMetadata.isCryptexIndex && <MobileTradingViewWidget />}
      {isMobile && marketMetadata.isCryptexIndex && <TradingViewChart />}
      {!isMobile && (
        <div className="trade-left">
          <MarketInfoMetrics />
          {!marketMetadata.isCryptexIndex ? (
            <TradingViewWidget />
          ) : (
            <div style={{ height: "90%" }}> 
              <TradingViewChart />
            </div>    
          )}
        </div>
      )}
    </>
  );
}

const TradeWrapper = ({
  isProInterface,
}: {
  isProInterface: boolean,
  currentInterface?: number,
}) => {
  ReactGA.send({
    hitType: "pageview",
    page: !isProInterface ? "/perpetuals" : "/perpetuals/provide",
    title: !isProInterface ? "Perpetuals Trade" : "Provide Liquidity",
  });
  const cryptexProducts = useCryptexProductsContext()
  
  return (
    <MarketProvider isMaker={isProInterface}>
      <LivePricesProvider>
        {cryptexProducts.isPerpetualsV1() ? (
          <Markets />
        ) : (
          <Trade isProInterface={isProInterface} />
        )}
      </LivePricesProvider>
    </MarketProvider>
  )
}

export default TradeWrapper;
