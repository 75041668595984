import React from "react"
import { Stack } from "react-bootstrap";

import { IssueForm } from "./components/IssueForm";


const Crypdex = () => (
  <Stack className="crypdex w-100 h-92 align-items-center" style={{ paddingTop: "5rem" }}>
    <IssueForm />
  </Stack>
);

export default Crypdex;
