import React from "react";
import { Alert, Stack } from "react-bootstrap";

import { useGeofenceContext } from "../../contexts";
import { useTranslation } from "react-i18next";


export const GeofenceBanner = () => {
  const { t } = useTranslation()
  const geofence = useGeofenceContext();
    
  if (!geofence.appDisabled) return <></>;
  
  return (
    <Alert className="notification-box" style={{ borderColor: "red" }}>
      <Stack direction="horizontal">
        <Stack direction="horizontal" className="w-90" gap={2}>
          <p>
            {geofence.isUsingProxy() || geofence.isUsingVpn()
            ? "You are using a VPN or Proxy. Please disable it to access the app."
            : t("info-msg.geoblock")}
            {" "}Read the  <a href="/terms-of-service" target="_blank">Terms and Conditions.</a>
          </p>
        </Stack>
      </Stack>  
    </Alert>  
  )
}
