import React, { useMemo } from "react";
import { Button, Image, Stack } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { formatUnits } from "viem";

import { IndexMetadata, SupportedIndexes } from "../../../constants/indexes";
import UniswapLogo2 from '../../../assets/icons/uniswap2.png';
import ethIcon from '../../../assets/icons/eth.png';
import CtxIcon from '../../../assets/ctx-icon.png';
import { useUserRewards } from "../../../hooks/incentives";
import { useIncentivesTransactions } from "../../../hooks/vaults/incentives";
import { useIncetivesAPY } from "../../../hooks/incentives/apy";


const UniswapV3Pool = "https://app.uniswap.org/explore/pools/base/0x2B70CeA59a418d77265482564610EF4D681D5ad6";

export const CtxIncentive = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const tcapMetadata = IndexMetadata[SupportedIndexes.tcap]; 
  const { data } = useUserRewards();
  const { onClaimCtx } = useIncentivesTransactions();
  const { data: apy } = useIncetivesAPY(SupportedIndexes.tcap);

  const { userRewards } = useMemo(() => { 
    if (!data || data.userRewards.length === 0) return { userRewards: { claimed: 0n, availableToClaim: 0n } };

    return {
      userRewards: { claimed: BigInt(data.userRewards[0].claimed), availableToClaim: BigInt(data.userRewards[0].availableToClaim) },  
    };
  }, [data]);

  const onClaim = async () => { 
    await onClaimCtx(userRewards.availableToClaim);
  }

  return (
    <Stack direction="vertical" gap={4} className="py-3 px-1 w-70 align-self-center border-box">
      <Stack className="py-2 px-3" gap={2}>
        <h4>TCAP Liquidity Providers Emissions</h4>
        <Stack>
          <p className="text-muted-light">
            A total of <span className="text-purple">178,000 CTX</span> will be distributed among
            {" "}<a href={UniswapV3Pool} target="_blank" rel="noreferrer" >TCAP/WETH pool</a> liquidity providers, over six months. To be eligible,
            liquidity must stay in range, with rewards distributed weekly.
          </p>
        </Stack>
        <Stack className="mt-2">
          <p className="text-muted-light">
            The <span className="text-purple">APY</span> shown below is an <span className="text-purple">estimate</span> influenced by several key
            factors: the proportion of in-range liquidity, the total pool assets held by in-range liquidity providers, pool fees, and the CTX price.
          </p>
        </Stack>
      </Stack>
      <Stack className="py-2 px-3" direction="vertical" gap={4}>
        <Stack className="" direction="vertical" gap={1}>
          <Stack direction="horizontal" gap={2} className="not-on-mobile px-3 py-1">
            <Stack className="w-30">
              <span className="small text-muted">Pool</span>
            </Stack>
            <Stack className="w-15 align-items-end">
              <span className="small text-muted ps-1 pe-2">APY</span>
            </Stack>
            <Stack className="w-17 align-items-end pe-2">
              <span className="small text-muted">Claimed</span>
            </Stack>
            <Stack className="w-18 align-items-end pe-2">
              <span className="small text-muted">Available to Claim</span>
            </Stack>
            <Stack className="w-20" />
          </Stack>
          <Stack direction="horizontal" gap={2} className="border-box-light incetive-pool py-1 ps-1 pe-3">
            <a
              href={UniswapV3Pool}
              target="_blank"
              rel="noreferrer"
              className={`flex-row text-decoration-none py-1 px-2 ref-button-box ${!isMobile ? "w-30" : "w-100"}`}
            >
              <Stack direction="horizontal" gap={1}>
                <Image src={tcapMetadata.icon} height={39} width={39} />
                <Image src={ethIcon} height={36} width={36} />
              </Stack>
              <Stack direction="vertical" gap={0} className="ps-2">
                <span>
                  {tcapMetadata.symbol.toUpperCase()} / WETH Pool
                </span>
                <Stack direction="horizontal" gap={1} className="aling-items-center" style={{ marginTop: "-5px" }}>
                  <Image src={UniswapLogo2} height={16} width={16} />
                  <span className="small text-muted mt-1">Uniswap V3</span>
                </Stack>
              </Stack>
            </a>
            <Stack direction="horizontal" gap={1} className={`incetive-value justify-content-end align-items-center ${!isMobile ? "w-15" : "w-100"}`}>
              <h6 className="only-mobile">
                APY:
              </h6>
              <Stack direction="horizontal" gap={1}>
                <span className="text-green">
                  {apy ? `${(apy * 100).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%` : "-"}
                </span>
              </Stack>  
            </Stack>
            <Stack direction="horizontal" gap={1} className={`incetive-value justify-content-end align-items-center ${!isMobile ? "w-17" : "w-100"}`}>
              <h6 className="only-mobile">
                Claimed:
              </h6>
              <Stack direction="horizontal" gap={1}>
                <span>
                  {parseFloat(formatUnits(userRewards.claimed, 18)).toFixed(4)}
                </span>
                <Image src={CtxIcon} width={24} height={24} />
              </Stack>  
            </Stack>
            <Stack direction="horizontal" gap={1} className={`incetive-value justify-content-end align-items-center ${!isMobile ? "w-18" : "w-100"}`}>
              <h6 className="only-mobile">
                Available to Claim:
              </h6>
              <Stack direction="horizontal" gap={1}>
                <span>
                  {parseFloat(formatUnits(userRewards.availableToClaim, 18)).toFixed(4)}
                </span>
                <Image src={CtxIcon} width={24} height={24} />
              </Stack>  
            </Stack>
            <Stack className={`justify-content-center align-items-end ${!isMobile ? "w-20" : "w-100"}`}>
              <Button
                onClick={() => onClaim()}
                disabled={userRewards.availableToClaim < 100n}
                style={{ width: !isMobile ? "7rem" : "100%" }}
              >
                Claim
              </Button>
            </Stack>  
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
